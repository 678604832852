/* eslint import/prefer-default-export: "off" */
import { Editor } from "@tiptap/core";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";

import { Bool } from "@bryq/src/utils/text_editor/extensions/bool";
import { Div } from "@bryq/src/utils/text_editor/extensions/div";
import { Toolbar } from "@bryq/src/utils/text_editor/extensions/toolbar";
import { Variable } from "@bryq/src/utils/text_editor/extensions/variable";
import {
  deserialize,
  serialize,
} from "@bryq/src/utils/text_editor/serializers";

export const useEmailTextEditor = (controller, target, options = {}) => {
  const originalDisplay = target.style.display;

  // Creates a container div for the editor before appending the container to
  // the `element` specified in `options`.
  const container = document.createElement("div");
  container.classList.add("b-editor", "b-editor-rich");

  const { content, dir, element, placeholder, variables, ...editorOptions } = {
    content: target.value,
    dir: target.dir,
    element: target.parentNode,
    placeholder: target.placeholder,
    variables: [],
    // Hides the original target input when the editor is ready.
    onCreate({ editor }) {
      // eslint-disable-next-line no-param-reassign
      target.style.display = "none";

      // Copies the target's `data-action` attribute.
      // eslint-disable-next-line no-param-reassign
      editor.view.dom.dataset.action = target.dataset.action;
    },
    // Propagates changes to the original target input.
    onUpdate({ editor }) {
      const value = serialize(editor.getHTML(), variables);
      // eslint-disable-next-line no-param-reassign
      target.value = value;
    },
    // Removes the container element on destroy.
    onDestroy() {
      container.remove();
    },
    ...options,
  };

  const vars = variables?.filter?.((v) => v.kind !== "boolean");
  const bools = variables?.filter?.((v) => v.kind === "boolean");

  // Appends the container into the `element` specified in `options` before
  // creating the editor inside of it.
  element.insertBefore(container, target);

  // Sets the container's dir.
  container.setAttribute("dir", dir);

  const tiptap = new Editor({
    content: deserialize(content, variables),
    element: container,
    extensions: [
      StarterKit.configure({
        blockquote: false,
        code: false,
        codeBlock: false,
        paragraph: false,
        dropcursor: false,
        gapcursor: false,
        heading: { levels: [1] },
        horizontalRule: false,
        strike: false,
      }),
      Div,
      Link.configure({
        openOnClick: false,
        HTMLAttributes: { class: undefined, rel: undefined, target: undefined },
      }),
      Placeholder.configure({ placeholder }),
      Variable.configure({ choices: vars }),
      Bool.configure({ choices: bools }),
      Toolbar.configure({ container, dir, isRich: true, variables }),
    ],
    ...editorOptions,
  });

  // Keeps a copy of the current disconnect() function of the controller to
  // support composing several behaviors.
  const controllerDisconnect = controller.disconnect.bind(controller);

  // Destroys the editor if the controller disconnects, and removes the
  // container element.
  Object.assign(controller, {
    disconnect() {
      tiptap.destroy();
      container.remove();
      // eslint-disable-next-line no-param-reassign
      target.style.display = originalDisplay;
      controllerDisconnect();
    },
  });

  return tiptap;
};
