import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "warning"];

  static classes = ["hidden"];

  // Max personality traits selected before a warning is shown.
  warningThreshold = 10;

  connect() {
    this.toggleWarning();
  }

  /**
   * Toggles the warning message when the parent dialog is discarded.
   */
  dialogDiscarded(e) {
    const { target } = e;
    if (target.contains(this.element)) {
      this.toggleWarning();
    }
  }

  /**
   * Toogles the warning message based on the number of selected personality
   * traits.
   */
  toggleWarning() {
    const selected = this.inputTargets.reduce(
      (acc, input) => (input.checked && input.value !== "" ? acc + 1 : acc),
      0,
    );

    if (this.hasWarningTarget) {
      this.warningTarget.classList.toggle(
        this.hiddenClass,
        selected <= this.warningThreshold,
      );
    }
  }
}
