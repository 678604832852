import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

const ANIMATION_MINIMUM_DURATION_MS = 5000;

// This variable is used to store the current request that is being processed
// and eventual streams that would received from the websocket while the
// request is being processed. Rendering the streams directly could be a
// problem as their content could be overwritten by the new form after the
// request is done.
// There can be only one request at a time.
const STATE = {
  lastProfileGenerationStartDate: null,
  animationPromise: null,
  generateProfileRenderedPromise: null,
};

export default class extends Controller {
  static targets = [
    "form",
    "topFieldset",
    "dialog",
    "select",
    "searchableSelect",
    "generateProfileButton",
    "validationButton",
    "clearExternalJobButton",
    "externalJobItem",
    "profileSection",
    "profileSectionAnimationContainer",
  ];

  static classes = ["hidden"];

  static values = {
    generateProfileKey: String,
    validateAssessmentSettingsKey: String,
  };

  externalJobFieldMap = {
    "lever.leverposting": "lever_posting",
    "successfactors.successfactorsjob": "successfactors_job",
    "zoho.zohojob": "zoho_job",
  };

  initialize() {
    this.selects = [];
    this.previousValues = {};

    this.handleBeforeStreamRender = this.beforeStreamRender.bind(this);
  }

  connect() {
    document.addEventListener(
      "turbo:before-stream-render",
      this.handleBeforeStreamRender,
    );
  }

  /**
   * Closes all the `dialog` targets, and opens the dialog with the given id.
   *
   * Stores the form data right before opening the dialog, so it can be put
   * back in the form when the dialog is closed discarding changes.
   */
  showDialog({ params }) {
    this.dialogTargets.forEach((dialog) => {
      if (dialog.id === params.dialogId) {
        this.storePreviousValues(dialog);
        dialog.showModal();
      } else {
        dialog.close();
      }
    });
  }

  /**
   * Closes all the `dialog` targets, discarding their data, bringing back the
   * data stored in `this.previousValues` for any dialog that was open.
   *
   * Dispatches the `dialog-discarded` event to signal that all the input
   * values are back to their previous state for the dialog that was discarded.
   */
  discardDialogs() {
    this.dialogTargets.forEach((dialog) => {
      if (dialog.open) {
        this.restorePreviousValues(dialog);
        dialog.close();
        this.dispatch("dialog-discarded", { target: dialog });
      }
    });
  }

  /**
   * Checks if an element is a restorable element:
   *  - is inside the dialog
   *  - has a name
   *  - has an id
   *  - is not a button
   *  - is not a fieldset
   *  - is not a submit button
   */
  // eslint-disable-next-line class-methods-use-this
  isRestorableDialogInput(dialog, element) {
    return (
      dialog.contains(element) &&
      !!element.name &&
      !!element.id &&
      element.type !== "button" &&
      element.type !== "fieldset" &&
      element.type !== "submit"
    );
  }

  /**
   * For a given dialog, finds all of the form's input elements in the dialog,
   * excluding buttons and none-input elements like fieldsets, storing the
   * current input value, or checked state in the case of checbox and radio
   * inputs, in `this.previousValues` that can later be used to restore them.
   */
  storePreviousValues(dialog) {
    [...this.formTarget.elements].forEach((element) => {
      if (this.isRestorableDialogInput(dialog, element)) {
        let value;
        if (element.type === "checkbox" || element.type === "radio") {
          value = element.checked;
        } else {
          value = element.value;
        }
        // eslint-disable-next-line no-param-reassign
        this.previousValues[element.id] = value;
      }
    });
  }

  /**
   * For a given dialog, finds all of the form's input elements in the dialog,
   * excluding buttons and none-input elements like fieldsets, and restores the
   * value/checked state from `this.previousValues`.
   *
   * Dispatches a `previous-value-restored` event to notify elements that may
   * need to do more work when their value changes programatically.
   * Inputs can subscribe to this event to make more adjustments.
   */
  restorePreviousValues(dialog) {
    [...this.formTarget.elements].forEach((element) => {
      if (this.isRestorableDialogInput(dialog, element)) {
        const value = this.previousValues[element.id];
        if (value !== undefined) {
          if (element.type === "checkbox" || element.type === "radio") {
            // eslint-disable-next-line no-param-reassign
            element.checked = value;
          } else {
            // eslint-disable-next-line no-param-reassign
            element.value = value;
          }
          // Dispatches a `previous-value-restored` to notify elements that may
          // need to do more work when their value changes programatically.
          this.dispatch("previous-value-restored", { target: element });
          // Clears the used previous value.
          this.previousValues[element.id] = undefined;
        }
      }
    });
  }

  /**
   * Shows the `clearExternalJobButtonTarget` targets.
   */
  clearExternalJobButtonTargetConnected(target) {
    target.classList.remove(this.hiddenClass);
  }

  /**
   * Clears the external job input fields for the ATS given by the button's
   * integration param.
   * Removes the `li` of that ATS.
   */
  clearExternalJob(e) {
    const {
      params: { field },
    } = e;

    if (this.hasFormTarget) {
      this.formTarget.elements[field].value = "";
      this.formTarget.elements[`${field}_full_name`].value = "";
    }

    this.externalJobItemTargets.forEach((item) => {
      if (item.dataset.field === field) {
        item.remove();
      }
    });
  }

  /**
   * Replaces the external job input fields for the ATS with the new external
   * job, and calls validate to update the form.
   * Also updates the form's `name`, `department` and `description` fields.
   */
  addExternalJob(e) {
    const {
      detail: { externalJob },
    } = e;
    const field = this.externalJobFieldMap[externalJob.model];

    if (this.hasFormTarget) {
      const name = [externalJob.fields.name, externalJob.fields.location]
        .filter(Boolean)
        .join(", ");
      this.formTarget.elements.name.value = name;
      this.formTarget.elements.department.value =
        externalJob.fields.department || "";

      // Handles the description field missing in case of validated role input.
      if (this.formTarget.elements.description) {
        this.formTarget.elements.description.value =
          externalJob.fields.description || "";
      }

      const fullName = [
        externalJob.fields.name,
        externalJob.fields.department,
        externalJob.fields.location,
      ]
        .filter(Boolean)
        .join(", ");
      this.formTarget.elements[field].value = externalJob.fields.id;
      this.formTarget.elements[`${field}_full_name`].value = fullName;

      if (this.hasValidationButtonTarget) {
        this.validationButtonTarget.click();
      }
    }
  }

  /**
   * Sets up and a SlimSelect from a target with optional options.
   * If the first option is empty, it is considered a placeholder.
   */
  setupSlimSelect(target, options = {}) {
    let allowDeselect = false;
    const firstChild = target.querySelector("option:first-child");
    if (firstChild.value === "") {
      firstChild.dataset.placeholder = "true";
      allowDeselect = true;
    }
    const select = new SlimSelect({
      select: target,
      settings: { allowDeselect, ...options },
    });
    this.selects.push(select);
  }

  selectTargetConnected(target) {
    this.setupSlimSelect(target, { showSearch: false });
  }

  searchableSelectTargetConnected(target) {
    this.setupSlimSelect(target, { showSearch: true });
  }

  /**
   * Enables/disables the `generateProfileButtonTarget` based on the validity
   * of the target field, in this case the `description` field.
   */
  toggleGenerateProfileButton(e) {
    if (this.hasGenerateProfileButtonTarget) {
      const { currentTarget } = e;
      const { value } = currentTarget;
      const isValid = currentTarget.checkValidity() && value.trim().length > 0;
      this.generateProfileButtonTarget.disabled = !isValid;
    }
  }

  /**
   * Scrolls to the `profileSection` target when a profile is generated.
   */
  scrollToProfileSection() {
    if (this.hasProfileSectionTarget) {
      this.profileSectionTarget.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  startGenerateProfileAnimation(event) {
    const { detail: { formSubmission = {} } = {} } = event;
    STATE.currentFormSubmission = formSubmission;

    // Updates the last profile generation start date to the current time
    // and create new promises that can be used to wait until the animation
    // for the profile generation is done and the stream for the profile
    // generation has been rendered.
    let generateProfileAnimationEndHandler;
    let generateProfileStreamRenderedHandler;

    STATE.lastProfileGenerationStartDate = Date.now();
    STATE.animationPromise = new Promise((resolve) => {
      generateProfileAnimationEndHandler = () => {
        resolve();
      };

      // Resolve the promise once the animation for the profile generation
      // has ended or after 30 seconds if the event is not fired.
      this.element.addEventListener(
        "generate-profile-animation-end",
        generateProfileAnimationEndHandler,
        { once: true },
      );
      setTimeout(() => {
        resolve();
      }, 30000);
    }).then(() => {
      // Reset the state variables once the animation is done and remove the
      // event listener.
      this.element.removeEventListener(
        "generate-profile-animation-end",
        generateProfileAnimationEndHandler,
      );
    });

    // Create a promise that resolves once the stream for the profile
    // generation has been rendered. That's the trigger to render any other
    // streams that would have been received while the animation was running
    // and after the stream for the profile generation has been received.
    // This way we can ensure the rendering order of the different streams.
    STATE.generateProfileRenderedPromise = new Promise((resolve) => {
      generateProfileStreamRenderedHandler = () => {
        resolve();
      };
      this.element.addEventListener(
        "generate-profile-stream-rendered",
        generateProfileStreamRenderedHandler,
        { once: true },
      );
      setTimeout(() => {
        resolve();
      }, 30000);
    }).then(() => {
      this.element.removeEventListener(
        "generate-profile-stream-rendered",
        generateProfileStreamRenderedHandler,
      );
    });

    this.scrollToProfileSection();
    this.topFieldsetTarget.disabled = true;
    this.profileSectionAnimationContainerTarget.classList.remove(
      this.hiddenClass,
    );
  }

  /**
   * This method filters the submit events from the form validation and
   * dispatches the `main-submit-start` event that then can be listened to by
   * other controllers interested in the fomr submission such as the
   * `LoaderOverlay` controller.
   *
   * profile request.
   *
   * @param {Event} event
   */
  submitStart(event) {
    const submitterName = event.detail.formSubmission.submitter.name;
    const skipDispatch =
      submitterName === "_validate_form" ||
      submitterName === this.validateAssessmentSettingsKeyValue;

    if (skipDispatch) {
      return;
    }

    if (submitterName === this.generateProfileKeyValue) {
      this.startGenerateProfileAnimation(event);
      return;
    }

    this.dispatch("main-submit-start", event);
  }

  /**
   * This method filters the submit events from the form validation and
   * dispatches the main-submit-end event that then can be listened to by other
   * controllers interested in the form submission such as the `LoaderOverlay`
   * controller.
   *
   * @param {Event} event
   */
  submitEnd(event) {
    const { detail: { success, formSubmission = {} } = {} } = event;
    const { submitter = {} } = formSubmission;
    const { name: submitterName } = submitter;

    const skipDispatch =
      submitterName === "_validate_form" ||
      submitterName === this.validateAssessmentSettingsKeyValue ||
      submitterName === this.generateProfileKeyValue;

    if (submitterName === this.generateProfileKeyValue) {
      const { lastProfileGenerationStartDate } = STATE;
      const elapsedTime = Date.now() - lastProfileGenerationStartDate;
      const remainingTime = Math.max(
        ANIMATION_MINIMUM_DURATION_MS - elapsedTime,
        0,
      );
      setTimeout(() => {
        this.element.dispatchEvent(new Event("generate-profile-animation-end"));
      }, remainingTime);
    }

    if (skipDispatch) {
      return;
    }

    this.dispatch("main-submit-end", event);

    if (success && submitterName !== "_ml_operation_feedback_form") {
      this.dispatch("form-submitted-with-success", event);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async beforeStreamRender(event) {
    const fallbackToDefaultActions = event.detail.render;

    const {
      detail: { newStream },
    } = event;
    const { type } = newStream.dataset;

    if (type === "generate-profile") {
      // eslint-disable-next-line no-param-reassign
      event.detail.render = async (streamElement) => {
        await STATE.animationPromise;

        // Trigger the animation end event to render the stream.
        this.profileSectionAnimationContainerTarget.classList.add(
          this.hiddenClass,
        );
        // Wait for the CSS animation to finish before rendering the stream.
        setTimeout(() => {
          fallbackToDefaultActions(streamElement);
          this.element.dispatchEvent(
            new Event("generate-profile-stream-rendered"),
          );
        }, 500);
      };
    }

    if (
      type === "job-hard-skills-preview" ||
      type ===
        "ml-operations-information-extraction-from-job-description-operation-log-feedback-form"
    ) {
      // eslint-disable-next-line no-param-reassign
      event.detail.render = async (streamElement) => {
        await STATE.generateProfileRenderedPromise;
        fallbackToDefaultActions(streamElement);
      };
    }
  }

  disconnect() {
    this.selects.forEach((select) => select.destroy());
    this.selects = [];
    document.removeEventListener(
      "turbo:before-stream-render",
      this.handleBeforeStreamRender,
    );
  }
}
