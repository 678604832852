import { Controller } from "@hotwired/stimulus";
import { embedDashboard } from "@preset-sdk/embedded";

export default class extends Controller {
  static values = {
    embeddedDashboardId: String,
    presetGuestToken: String,
    supersetDomain: String,
  };

  connect() {
    embedDashboard({
      id: this.embeddedDashboardIdValue,
      supersetDomain: this.supersetDomainValue,
      mountPoint: this.element,
      fetchGuestToken: () => this.presetGuestTokenValue,
      dashboardUiConfig: {},
    });
  }
}
