import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

import { IS_WEBAPP } from "@bryq/src/settings";

export default class extends Controller {
  static targets = ["submitButton", "radio", "select"];

  static classes = ["hidden"];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }

    this.selects = this.selectTargets.map((selectTarget) => {
      // Marks the first option as the placeholder.
      // eslint-disable-next-line no-param-reassign
      selectTarget.querySelector("option:first-child").dataset.placeholder =
        "true";

      return new SlimSelect({
        select: selectTarget,
        settings: { allowDeselect: true, showSearch: true },
      });
    });

    this.toggleSelect();
  }

  toggleSelect() {
    const checkedRadioIndex = this.radioTargets.findIndex(
      (radio) => radio.checked,
    );
    if (checkedRadioIndex !== -1) {
      this.selects.forEach((select, index) => {
        if (index === checkedRadioIndex) {
          select.enable();
        } else {
          select.disable();
        }
      });
    }
  }

  submit(event) {
    /**
     * Hack to make the job select work on the webapp. To be removed when the
     * webapp is retired.
     */
    if (IS_WEBAPP) {
      const checkedRadioIndex = this.radioTargets.findIndex(
        (radio) => radio.checked,
      );
      const checkedRadioTarget = this.radioTargets[checkedRadioIndex];
      const selectTarget = this.selectTargets[checkedRadioIndex];

      const instanceType = checkedRadioTarget.value;

      let jobId = "";
      let profileTemplateId = "";

      const { srcElement } = event;

      if (srcElement.id !== selectTarget.id) {
        return;
      }

      if (instanceType === "job") {
        jobId = selectTarget.value;
      } else {
        profileTemplateId = selectTarget.value;
      }

      const { href } = window.location;
      const url = new URL(href);

      url.searchParams.set("instance_type", instanceType);
      url.searchParams.set("job", jobId);
      url.searchParams.set("profile_template", profileTemplateId);

      const to = url.href.replace(url.origin, "");

      // Prevents the router from navigating to the same page multiple times in
      // case the user clicks the submit button multiple times.
      const { __reactRouter: reactRouter } = window;
      const currentTo = `${reactRouter.state.location.pathname}${reactRouter.state.location.search}`;

      if (currentTo === to) {
        return;
      }

      event.preventDefault();

      reactRouter.navigate(to);
      return;
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  disconnect() {
    this.selects.forEach((select) => select.destroy());
  }
}
