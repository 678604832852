import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hardSkillsListWrapper"];

  static classes = ["hidden"];

  toggleHardSkillsListWrapperDisplay(event) {
    if (!this.hasHardSkillsListWrapperTarget) {
      return;
    }
    this.hardSkillsListWrapperTarget.classList.toggle(
      this.hiddenClass,
      !event.currentTarget.checked,
    );
  }
}
