import { Controller } from "@hotwired/stimulus";

import { useResponsive } from "@bryq/src/behaviors/use_responsive";
import { Breakpoint } from "@bryq/src/utils/responsive";

export default class extends Controller {
  static targets = [
    "checkbox",
    "scoringSystemRadio",
    "container",
    "headersRow",
    "cellsRow",
    "stickyCell",
    "stenScore",
    "bryqScore",
  ];

  static classes = ["showSelectorButton", "scoreHidden"];

  static values = {
    companyId: String,
  };

  initialize() {
    this.storageKey = `candidates--candidates-list--column-selector-columns:${this.companyIdValue}`;

    this.currentSelection = JSON.parse(
      localStorage.getItem(this.storageKey) || "{}",
    );
  }

  connect() {
    useResponsive(this);
  }

  containerTargetConnected(containerTarget) {
    containerTarget.classList.add(this.showSelectorButtonClass);
  }

  containerTargetDisconnected(containerTarget) {
    containerTarget.classList.remove(this.showSelectorButtonClass);
  }

  /**
   * Handle the breakpoint change event.
   * Hides the `assessed_on` and `job` columns on `small` and `x-small`
   * breakpoints. Restores the visibility of the columns based on the current
   * selection for the other breakpoints.
   * @param {Object} breakpoints
   * @param {string} breakpoints.new
   * @param {string} breakpoints.old
   */
  breakpointChange(breakpoints) {
    if (!this.hasHeadersRowTarget) {
      // The table is empty. Nothing to do.
      return;
    }
    const { new: newBreakpoint } = breakpoints;
    if (
      newBreakpoint === Breakpoint.SMALL ||
      newBreakpoint === Breakpoint.X_SMALL
    ) {
      this.toggleColumn("assessed_on", false);
      this.toggleColumn("job", false);
    } else {
      const assessedOnVisible = this.currentSelection.assessed_on !== false;
      const jobVisible = this.currentSelection.job !== false;
      this.toggleColumn("assessed_on", assessedOnVisible);
      this.toggleColumn("job", jobVisible);
    }
  }

  /**
   * Toggles the checkboxes based on `this.currentSelection`, but also sets
   * `this.currentSelection`  based on the initial state of any checkboxes it
   * was missing.
   */
  checkboxTargetConnected(checkBox) {
    if (checkBox.name in this.currentSelection) {
      // eslint-disable-next-line no-param-reassign
      checkBox.checked = this.currentSelection[checkBox.name];
    } else {
      this.currentSelection[checkBox.name] = checkBox.checked;
    }
    this.toggleColumn(checkBox.name, checkBox.checked);
  }

  scoringSystemRadioTargetConnected(radio) {
    if (radio.name in this.currentSelection) {
      if (this.currentSelection[radio.name] === radio.value) {
        // eslint-disable-next-line no-param-reassign
        radio.checked = true;
        this.toggleScores(radio.value);
      }
    } else if (radio.checked === true) {
      this.currentSelection[radio.name] = radio.value;
    }
  }

  /**
   * When the headers row target is connected, it hides/shows the columns based
   * on the current selection.
   * @param {HTMLElement} headersRowTarget
   */
  headersRowConnected(headersRowTarget) {
    Array.from(headersRowTarget.children).forEach((th) => {
      const { id } = th;
      const visible = this.currentSelection[id] !== false;
      // eslint-disable-next-line no-param-reassign
      th.style.display = visible ? "table-cell" : "none";
    });
  }

  /**
   * When the cells row target is connected, it hides/shows the columns based
   * on the current selection.
   * @param {HTMLElement} cellsRowTarget
   */
  cellsRowTargetConnected(cellsRowTarget) {
    Array.from(cellsRowTarget.children).forEach((cell, i) => {
      const { id } = this.headersRowTarget.children[i];
      if (!id) {
        return;
      }
      const matchingHeaderCell = this.headersRowTarget.children[i];
      // eslint-disable-next-line no-param-reassign
      cell.style.display = matchingHeaderCell.style.display;
      // eslint-disable-next-line no-param-reassign
      cell.style.left = matchingHeaderCell.style.left;
    });
  }

  /**
   * When the sten score target is connected, it hides/shows the score based
   * on the current selection.
   * @param {HTMLElement} stenScoreTarget
   */
  stenScoreTargetConnected(stenScoreTarget) {
    if (!this.currentSelection.scoring_system) {
      return;
    }
    const visible = this.currentSelection.scoring_system === "sten_scores";
    if (visible) {
      stenScoreTarget.classList.remove(this.scoreHiddenClass);
    } else {
      stenScoreTarget.classList.add(this.scoreHiddenClass);
    }
  }

  /**
   * When the bryq score target is connected, it hides/shows the score based
   * on the current selection.
   * @param {HTMLElement} bryqScoreTarget
   */
  bryqScoreTargetConnected(bryqScoreTarget) {
    if (!this.currentSelection.scoring_system) {
      return;
    }
    const visible = this.currentSelection.scoring_system === "bryq_scores";
    if (visible) {
      bryqScoreTarget.classList.remove(this.scoreHiddenClass);
    } else {
      bryqScoreTarget.classList.add(this.scoreHiddenClass);
    }
  }

  /**
   * Toggles a column visibility based on the `visible` parameter and updates
   * the left position of the columns on the left of the separator column and
   * of the separator column itself to ensure the correct sticky behavior.
   * @param {string} name
   * @param {boolean} visible
   */
  toggleColumn(name, visible) {
    // Hide/show the header for the column.
    const columnIndex = Array.from(this.headersRowTarget.children).findIndex(
      (th) => th.id === name,
    );
    this.headersRowTarget.children[columnIndex].style.display = visible
      ? "table-cell"
      : "none";

    // Hide/show the cells for the column.
    this.cellsRowTargets.forEach((row) => {
      // eslint-disable-next-line no-param-reassign
      row.children[columnIndex].style.display = visible ? "table-cell" : "none";
    });

    // Moves the columns on the left of the separator colums  and the separator
    // column to their new positions.
    // First, we need to find the column index of the separator column if any.
    // Then we need to ensure that all the columns to the left of the separator
    // column and the separator columns gets the correct left position.
    if (this.stickyCellTargets.length === 0) {
      return;
    }

    const stickyCell = this.stickyCellTargets[0]; // assumes it's the one in
    // the header row.
    const stickyCellsColumnIndex = Array.from(
      this.headersRowTarget.children,
    ).findIndex((th) => th === stickyCell);

    if (stickyCellsColumnIndex === -1) {
      throw new Error("Sticky cell not found in headers row.");
    }

    let offset = 0;
    for (let i = 0; i <= stickyCellsColumnIndex; i += 1) {
      this.headersRowTarget.children[i].style.left = `${offset}px`;
      // eslint-disable-next-line no-loop-func
      this.cellsRowTargets.forEach((row) => {
        // eslint-disable-next-line no-param-reassign
        row.children[i].style.left = `${offset}px`;
      });
      const boundingRect =
        this.headersRowTarget.children[i].getBoundingClientRect();
      offset += boundingRect.width;
    }
  }

  toggleScores(value) {
    const stenMethod = value === "sten_scores" ? "remove" : "add";
    const bryqMethod = value === "bryq_scores" ? "remove" : "add";

    this.stenScoreTargets.forEach((score) => {
      score.classList[stenMethod](this.scoreHiddenClass);
    });
    this.bryqScoreTargets.forEach((score) => {
      score.classList[bryqMethod](this.scoreHiddenClass);
    });
  }

  /**
   * Updates the column selection in the localStorage based on the checkbox
   * state and triggers the column visibility update.
   * @param {Event} event
   */
  updateColumnSelection(event) {
    const {
      currentTarget: { checked, name },
    } = event;

    this.toggleColumn(name, checked);

    // Writes the latest value of `currentSelection` to localStorage.
    this.currentSelection[name] = checked;

    localStorage.setItem(
      this.storageKey,
      JSON.stringify(this.currentSelection),
    );
  }

  /**
   * Updates the scores based on the event.
   * @param {Event} event
   */
  updateScores(event) {
    const {
      currentTarget: { value, name },
    } = event;

    this.toggleScores(value);

    // Writes the latest value of `currentSelection` to localStorage.
    this.currentSelection[name] = value;

    localStorage.setItem(
      this.storageKey,
      JSON.stringify(this.currentSelection),
    );
  }
}
