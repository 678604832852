import { Controller } from "@hotwired/stimulus";
import { visit } from "@hotwired/turbo";

/**
 * A simple controller that can be used on `a` tags to replace the normal
 * behaviour by a call to the ReacRouter `navigate` method.
 * This will allow to keep the main navigation of the web app managed and
 * handled by the ReactRouter.
 * The controller will also replace the URL in `href` with the final one to
 * be used so it can be copied and pasted in other places and still support
 * right click and open in new tab actions.
 * The controller will strip the protocol, domain and port of full urls.
 * Usage is as follows:
 *
 * ```
 * <body data-controller="utils--react-links">
 *   ...
 *     <a href="{{ WEBAPP_URL }}/fizz/buzz?bar=foo"
 *        data-utils--react-links-target="link"
 *        data-action="utils--react-links#navigate">
 *        Hello
 *     </a>
 *   ...
 * </body>
 * ```
 *
 * Alternatively, the controller can be used directly as an interface with the
 * ReactRouter by calling the `navigateTo` method directly and passing the url
 * as `data-utils--react-links-to-param` attribute.
 *
 * It's possible to use a navigation fallback that will try to trigger a Turbo
 * visit if the ReactRouter is not available. This can be done by adding the
 * `data-utils--react-links-turbo-fallback-to-param` attribute to the `body` tag
 * and eventually the `data-utils--react-links-turbo-fallback-frame-param` to
 *
 * A full example would be:
 *
 * ```
 * <body data-controller="utils--react-links">
 *  ...
 *  <form data-action="turbo:submit-end->utils--react-links#navigateTo"
 *      data-utils--react-links-to-param="{{ WEBAPP_URL }}/fizz/buzz?bar=foo"
 *      data-utils--react-links-turbo-fallback-to-param="{% url "fizz-buzz" %}"
 *      data-utils--react-links-turbo-fallback-frame-param="main-content">
 *    ...
 *  </form>
 *  ...
 * </body>
 * ```
 *
 * It is also possible to dispatch the `navigateTo` event directly from another
 * controller, or an event that triggers `navigateTo`, using `event.detail` to
 * pass `to`, `turboFallbackTo`, and `turboFallbackFrame`. For example:
 *
 * In HTML:
 *          <div data-controller="some-controller"
 *               data-action="some-controller:some-event->utils--react-links#navigateTo">
 *             ...
 *          </div>
 *
 * In the controller:
 *    this.dispatch("some-event", {
 *      detail: {
 *        to: "http://app.bryq.localhost:8080/some/path/",
 *        turboFallbackTo: "/some/path/",
 *        turboFallbackFrame: "main-content",
 *      },
 *    });
 *
 * Usually a controller per element would be better as it allows to instantiate
 * the controller with values and options. Here a global controller may be
 * better. This way it is enough to just specify the `data-action` on the links
 * that should use the React router.
 * - It makes the usage simpler than having one controller per link
 * - Stimulus should handle the add/remove of the event listeners
 * - Extra params can still be passed to the event. For more details:
 *   https://stimulus.hotwired.dev/reference/actions#action-parameters
 *   e.g.: `data-utils--react-links-fizz-param="buzz"`
 */
export default class extends Controller {
  static targets = ["link"];

  /**
   * Replace the href with the final url to be used based on the context
   * (the employers website or the webapp).
   * On the employers website, the url should not be changed and are expected
   * to be the internal ones by default.
   */
  // eslint-disable-next-line class-methods-use-this
  linkTargetConnected(target) {
    const { __reactRouter: reactRouter } = window;
    if (!reactRouter) {
      return;
    }

    const { href } = target;
    const isFullUrl = href.startsWith("http") === true;
    let to = href;

    const url = new URL(href);

    // Remove trailing slash from pathname becaude ReactRouter does not
    // handle it well... It will most likely add a trailing slash to the
    // pathname but it's not always able to remove it, especially when
    // there is another url that is similar but with a trailing slash and a
    // parameter.
    // eg: `/fizz/buzz` and `/fizz/buzz/:id`
    if (url.pathname.length > 1 && url.pathname.endsWith("/")) {
      url.pathname = url.pathname.slice(0, -1);
    }

    if (isFullUrl === true) {
      // Remove the origin part of the url to keep only the pathname and
      // search params.
      to = url.href.replace(url.origin, "");
    }

    target.dataset.originalHref = href; // eslint-disable-line no-param-reassign
    target.href = to; // eslint-disable-line no-param-reassign
  }

  /**
   * Navigate to the url using the ReactRouter if available, otherwise use
   * Turbo to visit the url.
   * All the urls are expeted to be the final ones to be used and will not be
   * modified at this point.
   */
  // eslint-disable-next-line class-methods-use-this
  navigate(event) {
    const { __reactRouter: reactRouter } = window;
    // Let it go if there is no ReactRouter or if the user is trying to open
    // the link in a new tab or window.
    if (!reactRouter || event.metaKey || event.ctrlKey || event.shiftKey) {
      return true;
    }

    const {
      currentTarget: { href },
    } = event;
    let to = href;

    // The web browser may have decided to add the origin part of the url
    // to the href when creating the event, so we need to check if it's a full
    // url that match the current origin and remove the origin part of the url
    // before navigating wiht the ReactRouter.
    const isFullUrlForCurrentOrigin =
      href.startsWith(window.location.origin) === true;
    if (isFullUrlForCurrentOrigin === true) {
      // Remove the origin part of the url to keep only the pathname and
      // search params to please the react Router
      const url = new URL(href);
      to = url.href.replace(url.origin, "");
    }
    event.preventDefault();

    reactRouter.navigate(to);
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  navigateTo(event) {
    const { __reactRouter: reactRouter } = window;
    let {
      params: { to: reactTo, turboFallbackTo, turboFallbackFrame },
    } = event;

    if (!reactTo && !turboFallbackTo) {
      // Checks if the params were passed with `event.detail` instead.
      const { detail } = event;
      reactTo = detail.to;
      turboFallbackTo = detail.turboFallbackTo;
      turboFallbackFrame = detail.turboFallbackFrame;

      if (!reactTo && !turboFallbackTo) {
        return true;
      }
    }

    let to = (reactRouter && reactTo) || turboFallbackTo;
    const isFullUrl = to.startsWith("http") === true;

    if (isFullUrl === true) {
      const url = new URL(to);
      to = url.href.replace(url.origin, "");
    }

    if (reactRouter && reactTo) {
      reactRouter.navigate(to);
      return true;
    }

    if (turboFallbackTo) {
      if (!isFullUrl) {
        visit(to, { frame: turboFallbackFrame });
      } else {
        window.location.href = turboFallbackTo;
      }
      return true;
    }
    return true;
  }

  /**
   * Update the url in the browser after a form submission that resulted in a
   * redirect.
   * This is useful when the form submission is done with Turbo and we want to
   * reflect the new url in the browser.
   * In the case of Turbo, it will just update the history state and nothing
   * else will happen.
   * In the case of the ReactRouter, it will navigate to the new url which
   * will trigger a re=-render of the page - which means that there may be new
   * resources fetched from the server.
   */
  // eslint-disable-next-line class-methods-use-this
  updateUrlAfterRedirect(event) {
    const { __reactRouter: reactRouter } = window;

    const {
      detail: { fetchResponse: { response: { url } = {} } = {} },
    } = event;

    if (!url) {
      // eslint-disable-next-line no-console
      console.error(
        "No url on the event. Make sure the orginal event related to the " +
          "form submission is being attached to the event passed to the " +
          "`updateUrlAfterRedirect` method.",
      );
    }

    if (reactRouter) {
      let to = new URL(url);

      if (to.pathname.length > 1 && to.pathname.endsWith("/")) {
        to.pathname = to.pathname.slice(0, -1);
      }

      to = to.href.replace(to.origin, "");

      reactRouter.navigate(to);
    } else {
      window.history.pushState({}, "", url);
    }
  }
}
