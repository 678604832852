import { Controller } from "@hotwired/stimulus";
import Fuse from "fuse.js";

import { readJsonScriptValue } from "@bryq/src/utils/json_script";

export default class extends Controller {
  static targets = ["externalJob", "errorList"];

  static classes = ["hidden"];

  static values = {
    jsonDataId: String,
    externalJobInputName: String,
  };

  connect() {
    this.externalJobs = readJsonScriptValue(this.jsonDataIdValue, {
      throws: true,
    });

    this.fuse = new Fuse(this.externalJobs, {
      keys: [
        "fields.name",
        "fields.description",
        "fields.location",
        "fields.department",
      ],
      isCaseSensitive: false,
      shouldSort: false, // We only change the visibility of the elements, not
      // their order.
      threshold: 0.4,
    });
  }

  filterExternalJobList(event) {
    const { currentTarget } = event;
    const { value } = currentTarget;

    if (value === "") {
      this.externalJobTargets.forEach((externalJobTarget) => {
        externalJobTarget.classList.remove(this.hiddenClass);
      });
      return;
    }

    const results = this.fuse.search(value);
    const visibleIds = results.reduce((acc, result) => {
      acc[result.item.fields.id] = true;
      return acc;
    }, {});

    this.externalJobTargets.forEach((jobTarget) => {
      const { id } = jobTarget.dataset;
      const isVisible = visibleIds[id] === true;

      if (isVisible) {
        jobTarget.classList.remove(this.hiddenClass);
      } else {
        jobTarget.classList.add(this.hiddenClass);
      }
    });
  }

  selectExternalJob(event) {
    event.preventDefault();
    const formData = new FormData(this.element);
    const selectedExternalJobId = formData.get(this.externalJobInputNameValue);

    if (selectedExternalJobId === null) {
      this.errorListTarget.classList.remove(this.hiddenClass);
      return;
    }

    const selectedExternalJob = this.externalJobs.find(
      (externalJob) => externalJob.fields.id === selectedExternalJobId,
    );

    if (!selectedExternalJob) {
      this.errorListTarget.classList.remove(this.hiddenClass);
      return;
    }

    this.errorListTarget.classList.add(this.hiddenClass);

    this.dispatch("external-job-selected", {
      detail: { externalJob: selectedExternalJob },
    });
  }
}
