import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

import { IS_WEBAPP } from "@bryq/src/settings";

export default class extends Controller {
  static targets = ["fieldset", "select", "submitButton"];

  static classes = ["hidden", "select"];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }

    if (this.hasSelectTarget) {
      this.selectTarget.classList.add(...this.selectClasses);
      // Marks the first option as the placeholder.
      this.selectTarget.querySelector(
        "option:first-child",
      ).dataset.placeholder = "true";

      // Assigns a class to each option based on its value.
      // This is a global class that is referenced in the CSS module.
      // There is no way to set an arbitrary data attribute on the select
      // options from here, which is carried forward by SlimSelect.
      // The alternative would be to set 5 classes on the controller for each
      // status, which seemed excessive.
      this.selectTarget.querySelectorAll("option").forEach((option) => {
        if (option.value) {
          option.classList.add(
            "status-select-option",
            `status-select-option-${option.value}`,
          );
        }
      });

      // Disables the select if its parent fieldset is disabled, so the
      // disabled state gets reflected in SlimSelect.
      if (this.hasFieldsetTarget) {
        this.selectTarget.disabled = this.fieldsetTarget.disabled;
      }

      this.statusSelect = new SlimSelect({
        select: this.selectTarget,
        settings: {
          allowDeselect: true,
          showSearch: false,
        },
      });
    }
  }

  submit(event) {
    /**
     * Hack to make the filters propagate to the webapp URL. To be removed when
     * the webapp is retired.
     */
    if (IS_WEBAPP) {
      event?.preventDefault();

      const { __reactRouter: reactRouter } = window;

      const { href } = window.location;
      const url = new URL(href);
      url.search = new URLSearchParams(new FormData(this.element)).toString();
      const to = url.href.replace(url.origin, "");

      // Prevents the router from navigating to the same page multiple times in
      // case the user clicks the submit button multiple times.
      const currentTo = `${reactRouter.state.location.pathname}${reactRouter.state.location.search}`;
      if (currentTo !== to) {
        reactRouter.navigate(to);
      }

      return;
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  handleSearch(event) {
    const {
      currentTarget: { value },
    } = event;
    if (value === "") {
      this.submit();
    }
  }

  disconnect() {
    if (this.statusSelect) {
      this.statusSelect.destroy();
    }
  }
}
