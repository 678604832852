import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

/**
 * Renders a tippy tooltip.
 * The content can be specified via a content value attribute, or a content
 * target which can be an element.
 */
export default class extends Controller {
  static targets = ["content", "element"];

  static values = {
    content: String,
    theme: { type: String, default: "dark" },
    trigger: { type: String, default: "mouseenter focus" },
  };

  connect() {
    const content = this.hasContentTarget
      ? this.contentTarget
      : this.contentValue;

    this.tooltip = tippy(this.elementTarget, {
      allowHTML: true,
      appendTo: "parent",
      content,
      delay: [120, 120],
      interactive: true,
      maxWidth: 380,
      placement: "bottom",
      theme: this.themeValue,
      trigger: this.triggerValue,
    });
  }

  close() {
    this.tooltip?.hide();
  }
}
