import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["hidden"];

  static targets = ["input", "selectAll", "selectAllContainer", "resetButton"];

  static values = { defaultCompanyIndicatorIds: Array };

  /**
   * Checks if all the enabled input targets are checked.
   */
  get allChecked() {
    const checked = this.inputTargets.every(
      (input) => input.disabled || input.checked,
    );
    return checked;
  }

  selectAllTargetConnected() {
    this.updateSelectAllCheckbox();
  }

  selectAllContainerTargetConnected(target) {
    target.classList.remove(this.hiddenClass);
  }

  resetButtonTargetConnected(target) {
    target.classList.remove(this.hiddenClass);
    this.updateResetButton();
  }

  handleChange() {
    this.updateSelectAllCheckbox();
    this.updateResetButton();
  }

  toggleSelectAll(e) {
    const {
      currentTarget: { checked },
    } = e;
    this.inputTargets.forEach((input) => {
      if (!input.disabled && input.checked !== checked) {
        input.click();
      }
    });
  }

  /**
   * Updates the `selectAll` target checkbox.
   */
  updateSelectAllCheckbox() {
    if (this.hasSelectAllTarget) {
      this.selectAllTarget.checked = this.allChecked;
    }
  }

  /**
   * Enables or disables the reset button based on if the default company
   * indicators are the only selected indicators.
   */
  updateResetButton() {
    if (this.hasResetButtonTarget) {
      const selectedIndicatorIds = this.inputTargets.reduce((acc, input) => {
        if (!input.disabled && input.checked) {
          acc.push(input.value);
        }
        return acc;
      }, []);

      const disabled =
        this.defaultCompanyIndicatorIdsValue.length ===
          selectedIndicatorIds.length &&
        this.defaultCompanyIndicatorIdsValue.every((value) =>
          selectedIndicatorIds.includes(value),
        );
      this.resetButtonTarget.disabled = disabled;
    }
  }

  /**
   * Resets the selected indicators to the default company indicators.
   */
  resetToCompanyDefaults() {
    this.inputTargets.forEach((input) => {
      const isDefault = this.defaultCompanyIndicatorIdsValue.includes(
        input.value,
      );
      if (!input.disabled && input.checked !== isDefault) {
        input.click();
      }
    });
    this.updateResetButton();
  }
}
