/* eslint import/prefer-default-export: "off" */
import { Node, mergeAttributes, nodeInputRule } from "@tiptap/core";

/**
 * A placeholder variable that has `key`, `kind`, and `label`.
 *
 * This gets converted by the editor to the string `{key}` on export.
 */
export const Variable = Node.create({
  name: "variable",

  atom: true,

  draggable: true,

  group: "inline",

  inline: true,

  addOptions() {
    return {
      choices: [],
    };
  },

  addAttributes() {
    return {
      key: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-key"),
        renderHTML: (attributes) => ({ "data-key": attributes.key }),
      },
      kind: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-kind"),
        renderHTML: (attributes) => ({ "data-kind": attributes.kind }),
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        rendered: false,
      },
    };
  },

  // Finds var tags with a data-key attr matching each choice key from choices.
  parseHTML() {
    return this.options.choices.map((choice) => ({
      tag: `var[data-key="${choice.key}"]`,
      attrs: { ...choice },
    }));
  },

  // Renders the var back with the label attr as its content.
  renderHTML({ node, HTMLAttributes }) {
    return ["var", mergeAttributes(HTMLAttributes), node.attrs.label];
  },

  // Renders the variable's key between curly brackets if pasting as text.
  renderText({ node }) {
    return `{${node.attrs.key}}`;
  },

  addCommands() {
    return {
      setVariable:
        (options) =>
        ({ commands }) =>
          commands.insertContent({
            type: this.name,
            attrs: options,
          }),
    };
  },

  // Converts known choices between two curly brackets into vars on input.
  addInputRules() {
    const rules = this.options.choices.map((choice) => `{${choice.key}}$`);
    return [
      nodeInputRule({
        find: new RegExp(rules.join("|")),
        type: this.type,
        getAttributes: (match) => {
          const key = match[0].replace(/{|}/g, "");
          return this.options.choices.find((choice) => choice.key === key);
        },
      }),
    ];
  },
});
