import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loader", "button"];

  static classes = ["hidden"];

  showLoader() {
    this.loaderTarget.classList.remove(this.hiddenClass);
    this.buttonTarget.classList.add(this.hiddenClass);
  }
}
