/* eslint import/prefer-default-export: "off" */
import { extendEvent } from "./stimulus";

/**
 * Breakpoints
 * Defines all the breakpoints that can be used on the website. They need to
 * match the ones defined in CSS in `assets/employers/css/media_queries.css`.
 * We also add the `print` breakpoint that is not defined in CSS.
 * The CSS defined breakpoints will be imported in JS and we will detect
 * breakpoint changes when the window gets resized.
 * Based on:
 * https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
 */
export const Breakpoint = {
  X_SMALL: "x-small",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  X_LARGE: "x-large",
  XX_LARGE: "xx-large",
  PRINT: "print",
};

Object.freeze(Breakpoint);

export const breakpoints = { old: null, new: null };

export const getBreakpointValue = () => {
  const breakpointValue = window
    .getComputedStyle(document.querySelector("body"), ":before")
    .getPropertyValue("content")
    .replace(/"/g, "");
  return breakpointValue;
};

export const isValidBreakpointValue = (value) =>
  Object.values(Breakpoint).includes(value);

// Tries to get a first value of the breakpoint (may fail if the CSS have not
// been fully applied yet)
const breakpointValue = getBreakpointValue();
breakpoints.new = breakpointValue;

// Attaches a global listener onto the page to track when the breakpoint
// changes.
const breakpointResizeListener = (event) => {
  const currentBreakpointValue = breakpoints.new;
  const newBreakpointValue = getBreakpointValue();
  const updateBreakpointValue =
    newBreakpointValue !== currentBreakpointValue &&
    isValidBreakpointValue(newBreakpointValue);

  if (updateBreakpointValue === true) {
    breakpoints.old = currentBreakpointValue;
    breakpoints.new = newBreakpointValue;
    const evt = extendEvent("breakpointchange", event, breakpoints);

    window.dispatchEvent(evt);
  }
};

window.addEventListener("resize", breakpointResizeListener);
