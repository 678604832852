/* eslint import/prefer-default-export: "off" */
export const readJsonScriptValue = (
  elId,
  options = { throws: false, defaultValue: undefined },
) => {
  const el = document.getElementById(elId);

  const { throws, defaultValue } = options;

  if (el === null) {
    if (throws === true) {
      throw new Error(`Element not found. '${elId}' is incorrect.`);
    }
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    return undefined;
  }

  const value = JSON.parse(el.textContent);
  return value;
};
