/* eslint import/prefer-default-export: "off" */
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import SlimSelect from "slim-select";

import { LinkDialog } from "./link_dialog";

const BUTTONS = {
  bold: {
    title: "Bold",
    command: ["toggleBold", {}],
    isRich: true,
  },
  italic: {
    title: "Italic",
    command: ["toggleItalic", {}],
    isRich: true,
  },
  link: {
    title: "Link",
    command: ["toggleLink", {}],
    isRich: true,
  },
  heading: {
    title: "Heading",
    command: ["toggleHeading", { level: 1 }],
    isRich: true,
  },
  bulletList: {
    title: "Bullets",
    command: ["toggleBulletList", {}],
    isRich: true,
  },
  orderedList: {
    title: "Numbers",
    command: ["toggleOrderedList", {}],
    isRich: true,
  },
};

class ToolbarView {
  buttons = {};

  variablesSelect = null;

  constructor({ container, dir, editor, isRich, variables, view }) {
    this.container = container;
    this.editor = editor;
    this.isRich = isRich;
    this.variables = variables;
    this.view = view;

    this.hasVariables = !!this.variables?.length;

    if (!this.isRich && !this.hasVariables) {
      return;
    }

    this.element = document.createElement("div");
    this.element.setAttribute("dir", dir);
    this.element.classList.add("b-editor-toolbar");

    this.container.insertBefore(this.element, this.container.firstChild);

    // Creates a container element for SlimSelect so it works with fixed
    // position <dialog /> elements.
    const selectContainer = document.createElement("div");
    selectContainer.classList.add(
      "b-hidden",
      "b-editor-variables-select-container",
    );
    this.container.appendChild(selectContainer);

    // Sets up the variables select.
    const options = [
      { placeholder: true, text: "", value: "" },
      ...variables.map((variable) => ({
        data: variable,
        text: variable.label,
        value: variable.key,
      })),
    ];
    const select = document.createElement("select");
    select.classList.add("b-editor-variables-select");
    // There is no other way to signal to SlimSelect that we need an rtl
    // dropdown.
    if (dir === "rtl") {
      select.classList.add("rtl");
    }
    this.element.appendChild(select);
    this.variablesSelect = new SlimSelect({
      data: options,
      events: {
        beforeChange: (newValue) => {
          this.variablesSelect.close();
          this.editor.chain().focus().setVariable(newValue[0].data).run();
          return false;
        },
        beforeOpen: () => {
          selectContainer.classList.remove("b-hidden");
        },
        afterClose: () => {
          selectContainer.classList.add("b-hidden");
        },
      },
      select,
      settings: {
        contentLocation: selectContainer,
        contentPosition: "relative",
        disabled: !this.hasVariables,
        openPosition: "down",
        placeholderText: "[ + ]",
        showSearch: false,
      },
    });

    // Adds the buttons and updates `self.buttons`.
    this.onClick = this.handleClick.bind(this);
    this.buttons = Object.entries(BUTTONS)
      .filter(([, attrs]) => !attrs.isRich || attrs.isRich === this.isRich)
      .reduce((acc, [name, attrs]) => {
        const el = document.createElement("button");
        el.setAttribute("name", name);
        el.setAttribute("title", attrs.title);
        el.setAttribute("type", "button");
        el.classList.add("b-editor-toolbar-button");
        // Only handles onClick if there is a command to be dispatched.
        if (attrs.command) {
          el.addEventListener("click", this.onClick);
        }
        this.element.appendChild(el);
        acc[name] = el;
        return acc;
      }, {});
  }

  /**
   * Handle button clicks.
   * Most button actions are defined in `BUTTONS` as simple commands with
   * possible options.
   *
   * The `link` and `variables` buttons are special and are handled
   * accordingly.
   *
   * The `link` button calls the custom `toggleLink` command, provided by the
   * `LinkDialog` plugin.
   */
  handleClick(e) {
    const { name } = e.target;
    const [command, options] = BUTTONS[name].command;
    this.editor.chain().focus()[command](options).run();
  }

  /**
   * Updates the active status of buttons on every editor update.
   */
  update({ state }) {
    Object.values(this.buttons).forEach((button) => {
      const isActive = this.editor.isActive(button.name);
      if (isActive) {
        button.classList.add("b-editor-toolbar-button-active");
      } else {
        button.classList.remove("b-editor-toolbar-button-active");
      }
    });

    // Enables or disables the link button according to the editor's state.
    // There has to be a text selection for the link button to be active.
    if ("link" in this.buttons) {
      const { selection } = state;
      const canLink =
        this.editor.isActive("link") ||
        (!selection.empty && selection.jsonID === "text");
      this.buttons.link.disabled = !canLink;
    }
  }

  /**
   * Cleans up:
   *   Destroys the variables SlimSelect instance.
   *   Releases all the button click event listeners.
   *   Removes `this.element` from the DOM.
   */
  destroy() {
    this.variablesSelect?.destroy();

    Object.values(this.buttons).forEach((button) => {
      button.removeEventListener("click", this.onClick);
    });

    this.element.remove();
  }
}

const ToolbarPlugin = (options) =>
  new Plugin({
    key: new PluginKey(options.pluginKey),
    view: (view) => new ToolbarView({ view, ...options }),
  });

export const Toolbar = Extension.create({
  name: "toolbar",

  addOptions() {
    return {
      container: undefined,
      dir: "ltr",
      pluginKey: "toolbar",
      isRich: false,
      variables: undefined,
    };
  },

  /**
   * Adds the LinkDialog extension for toolbars for rich text editors.
   */
  addExtensions() {
    const extensions = [];
    if (this.options.isRich) {
      extensions.push(LinkDialog);
    }
    return extensions;
  },

  addProseMirrorPlugins() {
    return [
      ToolbarPlugin({
        container: this.options.container,
        dir: this.options.dir,
        pluginKey: this.options.pluginKey,
        editor: this.editor,
        isRich: this.options.isRich,
        variables: this.options.variables,
      }),
    ];
  },
});
