import * as Sentry from "@sentry/browser";
import { captureConsoleIntegration } from "@sentry/browser";

import { APP_TARGET, GIT_REF, SENTRY_DSN_FRONTEND } from "@bryq/src/settings";

const release = `${APP_TARGET}-${GIT_REF}`;

Sentry.init({
  dsn: SENTRY_DSN_FRONTEND,
  release,
  environment: APP_TARGET,
  integrations: [
    captureConsoleIntegration({ levels: ["warn", "error", "assert"] }),
  ],
});
