import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

import { useUpdateWindowLocationSearch } from "@bryq/src/behaviors/use_update_window_location_search";
import { IS_WEBAPP } from "@bryq/src/settings";

export default class extends Controller {
  static targets = ["select", "selectWithSearch", "submitButton"];

  static classes = ["hidden"];

  static values = { companyId: String, urlEncodedQueryString: String };

  initialize() {
    if (!this.companyIdValue) {
      throw new Error("The `data-company-id-value` attribute is required.");
    }
    this.storageKey = `application-list-jobs-selected:${this.companyIdValue}`;
    this.currentFilterSelection = JSON.parse(
      localStorage.getItem(this.storageKey) || "{}",
    );
  }

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }

    this.slimSelects = this.selectTargets.map((selectTarget) => {
      // Marks the first option as the placeholder.
      // eslint-disable-next-line no-param-reassign
      selectTarget.querySelector("option:first-child").dataset.placeholder =
        "true";

      return new SlimSelect({
        select: selectTarget,
        settings: {
          allowDeselect: true,
          showSearch: false,
        },
      });
    });

    this.slimSelectsWithSearch = this.selectWithSearchTargets.map(
      (selectTarget) => {
        // Marks the first option as the placeholder.
        // eslint-disable-next-line no-param-reassign
        selectTarget.querySelector("option:first-child").dataset.placeholder =
          "true";

        return new SlimSelect({
          select: selectTarget,
          settings: {
            allowDeselect: true,
            showSearch: true,
          },
        });
      },
    );

    useUpdateWindowLocationSearch(this);
  }

  getNewUrlEncodedQueryStringValue() {
    if (IS_WEBAPP) {
      return window.location.search.slice(1);
    }
    return this.urlEncodedQueryStringValue;
  }

  submit(event) {
    /**
     * Hack to make the filters propagate to the webapp URL. To be removed when
     * the webapp is retired.
     */

    // Store the current filter selection in the local storage so it can be
    // restored when the user navigates back to the page.
    const {
      currentTarget: { name: filterName, value },
    } = event;

    this.currentFilterSelection[filterName] = value;
    localStorage.setItem(
      this.storageKey,
      JSON.stringify(this.currentFilterSelection),
    );

    if (IS_WEBAPP) {
      event.preventDefault();

      const { __reactRouter: reactRouter } = window;

      const { href } = window.location;
      const url = new URL(href);
      url.search = new URLSearchParams(new FormData(this.element)).toString();
      const to = url.href.replace(url.origin, "");

      // Prevents the router from navigating to the same page multiple times in
      // case the user clicks the submit button multiple times.
      const currentTo = `${reactRouter.state.location.pathname}${reactRouter.state.location.search}`;

      if (currentTo !== to) {
        reactRouter.navigate(to);
      }

      return;
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  disconnect() {
    this.slimSelects.forEach((select) => select.destroy());
    this.slimSelectsWithSearch.forEach((select) => select.destroy());
  }
}
