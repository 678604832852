import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["printButton"];

  static classes = ["supported"];

  connect() {
    if ("print" in window) {
      this.printButtonTarget.classList.add(this.supportedClass);
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  print() {
    window.print();
  }
}
