import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["closeButton", "fieldset", "submitButton", "loader"];

  static classes = ["hidden"];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }

    if (this.hasCloseButtonTarget) {
      this.closeButtonTarget.classList.remove(this.hiddenClass);
    }
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  /**
   * Disables the fieldset and closeButton targets when submit starts.
   */
  submitStart() {
    this.fieldsetTargets.forEach((fieldset) => {
      fieldset.setAttribute("disabled", "");
    });

    if (this.hasCloseButtonTarget) {
      this.closeButtonTarget.setAttribute("disabled", "");
    }

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove(this.hiddenClass);
    }
  }

  /**
   * Enables the fieldset and closeButton targets when submit ends.
   */
  submitEnd() {
    this.fieldsetTargets.forEach((fieldset) => {
      fieldset.removeAttribute("disabled");
    });

    if (this.hasCloseButtonTarget) {
      this.closeButtonTarget.removeAttribute("disabled");
    }

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.add(this.hiddenClass);
    }
  }
}
