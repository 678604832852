import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
// TODO: consider loading lottie player in parallel using something like import
// maps.
import "@lottiefiles/lottie-player";

import "./custom_elements/turbo_pusher_stream_source";
import "./sentry";

import "../css/index.css";

// Imports all the CSS modules in `bryq/templates`.
require.context("@bryq/ui", true, /\.module\.css$/);

// Sets up the Stimulus application by importing all the Stimulus Controllers in
// `bryq/templates` and registering them.
const application = Application.start();
const context = require.context("@bryq/ui", true, /\.js$/);
application.load(definitionsFromContext(context));

// Starts the Turbo application if it dos not already exist.
if (!window.Turbo) {
  const Turbo = require("@hotwired/turbo"); // eslint-disable-line global-require
  Turbo.start();
}

if (process.env.DEBUG === "1") {
  // Check https://stimulus.hotwired.dev/handbook/installing#error-handling
  // for more details about custom error handling
  application.debug = true;
}
