import { Controller } from "@hotwired/stimulus";

const State = {
  LESS: "LESS",
  MORE: "MORE",
};

/**
 * This controller is used to toggle the visibility of content within a section.
 * The content is hidden by default and can be shown by clicking on a button.
 * The initial state can be set using the `initialState` value.
 * The button label can be set using the `showMoreButtonLabel` and
 * `showLessButtonLabel` values.
 * The support for a class on the button to style it differently based on the
 * state of the content is not implemented yet.
 */
export default class ContentToggler extends Controller {
  static targets = ["toggleButton", "extraContent"];

  static classes = ["hidden"];

  static values = {
    initialState: { type: String, default: "LESS" },
    showMoreButtonLabel: String,
    showLessButtonLabel: String,
  };

  connect() {
    // Gets the initial state.
    let initialState = this.initialStateValue;
    if (!Object.values(State).includes(initialState)) {
      initialState = State[ContentToggler.values.initialState.default];
    }

    // Ensures that the targets are in the correct state.
    switch (initialState) {
      case State.LESS:
        this.hideExtraContent();
        break;
      case State.MORE:
        this.showExtraContent();
        break;
      default:
        break;
    }
  }

  showExtraContent() {
    this.toggleButtonTarget.innerHTML = this.showLessButtonLabelValue;
    this.extraContentTargets.forEach((insight) => {
      insight.classList.remove(this.hiddenClass);
    });
    this.isOpen = true;
  }

  hideExtraContent() {
    this.toggleButtonTarget.innerHTML = this.showMoreButtonLabelValue;
    this.extraContentTargets.forEach((insight) => {
      insight.classList.add(this.hiddenClass);
    });
    this.isOpen = false;
  }

  toggleExtraContentVisibility() {
    if (this.isOpen) {
      this.hideExtraContent();
    } else {
      this.showExtraContent();
    }
  }
}
