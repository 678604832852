import { Controller } from "@hotwired/stimulus";

import { useTurboFrameWaypointPagination } from "@bryq/src/behaviors/use_turbo_frame_waypoint_pagination";

export default class extends Controller {
  static targets = [
    "basicPagination",
    "advancedPagination",
    "loaderForPagination",
  ];

  static classes = ["hiddenPagination"];

  // eslint-disable-next-line class-methods-use-this
  connect() {
    useTurboFrameWaypointPagination(this);
  }
}
