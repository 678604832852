import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  /**
   * Changes the value of the inputs based on the current input's value, so that their total always adds up to 100.
   *
   * When an input is changed, all inputs under it are changed, unless it is
   * the last input, in which case, only the input above it is changed, until
   * 100 is reached.
   */
  handleInput(e) {
    const { currentTarget } = e;

    // The index of the current target.
    const index = this.inputTargets.findIndex(
      (input) => input === currentTarget,
    );
    const isLast = index === this.inputTargets.length - 1;

    // Finds the inputs that are locked and should not be modified.
    const lockedInputs = isLast
      ? this.inputTargets.slice(0, index - 1)
      : this.inputTargets.slice(0, index);

    const lockedWeight = lockedInputs.reduce(
      (acc, input) => acc + input.valueAsNumber,
      0,
    );

    // Finds the inputs that can be manipulated.
    const freeInputs = isLast
      ? this.inputTargets.slice(index - 1, index)
      : this.inputTargets.slice(index + 1, this.inputTargets.length);

    const availableWeight = 100 - lockedWeight - currentTarget.valueAsNumber;

    // Sets the current target's value to whatever weight was available, which
    // will stop it from moving, when the available weight runs out.
    if (availableWeight <= 0) {
      currentTarget.value = 100 - lockedWeight;
    }

    this.distributeWeight(freeInputs, availableWeight);

    this.dispatch("slider-input", { target: currentTarget });
  }

  /**
   * Distributes the available weight over the inputs that can be adjusted.
   * If the weight is positive, the smallest inputs are adjusted first, and if
   * the weight is negative, the largest inputs are adjusted first.
   */
  distributeWeight(inputs, availableWeight) {
    // Calculate current sum and determine the needed adjustment
    const currentTotal = inputs.reduce(
      (acc, input) => acc + input.valueAsNumber,
      0,
    );

    // Calculates the delta change needed.
    const delta = availableWeight - currentTotal;
    const direction = delta > 0 ? 1 : -1;

    const sliders = [...inputs];

    // Sorts the sliders by value, with the largets first if the delta is
    // negative (ie they need to lose weight first), and the smallest first if
    // the delta is positive (ie they need to gain weight first).
    sliders.sort((a, b) =>
      direction > 0
        ? a.valueAsNumber - b.valueAsNumber
        : b.valueAsNumber - a.valueAsNumber,
    );

    // For each tick of delta, adjusts the slider that should be adjusted
    // first.
    // If the sliders are losing weight, the largest slider left is targetted
    // first, and the opposite when gaining weight.
    for (let i = 0; i < Math.abs(delta); i += 1) {
      // Filters to sliders that can still be adjusted.
      const adjustable = sliders.filter((slider) =>
        direction > 0 ? slider.valueAsNumber < 100 : slider.valueAsNumber > 0,
      );

      if (adjustable.length === 0) {
        break;
      }

      adjustable[0].value = adjustable[0].valueAsNumber + direction;
    }

    // Dispatches `slider-input` events for every input, just once at the end.
    inputs.forEach((input) => {
      this.dispatch("slider-input", { target: input });
    });

    // Dispatches a `traits-changed` event with all of the new trait values.
    const values = this.inputTargets.reduce(
      (acc, input) => ({
        ...acc,
        [input.name.replace("profile_cognitive_", "")]: input.valueAsNumber,
      }),
      {},
    );
    this.dispatch("traits-changed", { detail: values });
  }
}
