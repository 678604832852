/* eslint import/prefer-default-export: "off" */
import { Editor } from "@tiptap/core";
import { History } from "@tiptap/extension-history";
import Placeholder from "@tiptap/extension-placeholder";
import { Text } from "@tiptap/extension-text";

import { Div } from "@bryq/src/utils/text_editor/extensions/div";
import { OneLine } from "@bryq/src/utils/text_editor/extensions/one-line";
import { Toolbar } from "@bryq/src/utils/text_editor/extensions/toolbar";
import { Variable } from "@bryq/src/utils/text_editor/extensions/variable";
import { deserialize } from "@bryq/src/utils/text_editor/serializers";

/**
 * Creates a tiptap email subject editor in target's parent by default.
 */
export const useEmailSubjectEditor = (controller, target, options = {}) => {
  const originalDisplay = target.style.display;

  // Creates a container div for the editor before appending the container to
  // the `element` specified in `options`.
  const container = document.createElement("div");
  container.classList.add("b-editor", "b-editor-plain");

  const { content, dir, element, placeholder, variables, ...editorOptions } = {
    content: target.value,
    dir: target.dir,
    element: target.parentNode,
    placeholder: target.placeholder,
    variables: [],
    // Hides the original target input when the editor is ready.
    onCreate({ editor }) {
      // eslint-disable-next-line no-param-reassign
      target.style.display = "none";

      // Copies the target's `data-action` attribute.
      // eslint-disable-next-line no-param-reassign
      editor.view.dom.dataset.action = target.dataset.action;
    },
    // Propagates changes to the original target input.
    onUpdate({ editor }) {
      // eslint-disable-next-line no-param-reassign
      target.value = editor.getText();
    },
    // Removes the container element on destroy.
    onDestroy() {
      container.remove();
    },
    ...options,
  };

  const textVariables = variables?.filter?.((v) => v.kind === "text");

  // Inserts the container into the `element` specified in `options` before
  // creating the editor inside of it.
  element.insertBefore(container, target);

  // Sets the container's dir.
  container.setAttribute("dir", dir);

  const tiptap = new Editor({
    content: deserialize(content, textVariables),
    element: container,
    extensions: [
      Text,
      OneLine,
      Div,
      History,
      Placeholder.configure({ placeholder }),
      Variable.configure({ choices: textVariables }),
      Toolbar.configure({
        container,
        dir,
        isRich: false,
        variables: textVariables,
      }),
    ],
    ...editorOptions,
  });

  // Keeps a copy of the current disconnect() function of the controller to
  // support composing several behaviors.
  const controllerDisconnect = controller.disconnect.bind(controller);

  // Destroys the editor if the controller disconnects, and removes the
  // container element.
  Object.assign(controller, {
    disconnect() {
      tiptap.destroy();
      container.remove();
      // eslint-disable-next-line no-param-reassign
      target.style.display = originalDisplay;
      controllerDisconnect();
    },
  });

  return tiptap;
};
