import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static targets = ["validationButton"];

  static debounces = ["validateForm"]; // default is 200ms

  formAsJsonString = "";

  initialize() {
    this.handleDisableValidateButtons = this.disableValidateButtons.bind(this);
    this.handleEnableValidateButtons = this.enableValidateButtons.bind(this);
  }

  connect() {
    // Ensure the web browser validation will not be used.
    this.element.noValidate = true;
    useDebounce(this);

    this.formAsJsonString = this.getFormAsJsonString();

    // Disables the validation button on submit start so it does not race the
    // actual submit and cancel the real submit request.
    this.element.addEventListener(
      "turbo:submit-start",
      this.handleDisableValidateButtons,
    );
    // Re-enables the validation button on submit end.
    this.element.addEventListener(
      "turbo:submit-end",
      this.handleEnableValidateButtons,
    );
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:submit-start",
      this.handleDisableValidateButtons,
    );
    this.element.removeEventListener(
      "turbo:submit-end",
      this.handleEnableValidateButtons,
    );
  }

  getFormAsJsonString() {
    const formData = new FormData(this.element);
    const data = Array.from(formData.entries()).map(([key, value]) => {
      let val;
      try {
        val = String(value).trim(); // safe for multi value fields
      } catch (e) {
        val = value;
      }
      return [key, val];
    });
    return JSON.stringify(data);
  }

  isDirty() {
    const formAsJsonString = this.getFormAsJsonString();
    return formAsJsonString !== this.formAsJsonString;
  }

  validateForm() {
    if (this.isDirty()) {
      this.validationButtonTargets.forEach((target) => target.click());
    }
  }

  disableValidateButtons() {
    this.validationButtonTargets.forEach((target) => {
      // eslint-disable-next-line no-param-reassign
      target.disabled = true;
    });
  }

  enableValidateButtons() {
    this.validationButtonTargets.forEach((target) => {
      // eslint-disable-next-line no-param-reassign
      target.disabled = false;
    });
  }
}
