import Combobox from "@github/combobox-nav";
import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

/**
 * Controller for the global search form.
 *
 * Uses the `Combobox` class from `@github/combobox-nav` to provide keyboard
 * navigation of search results.
 */
export default class extends Controller {
  static targets = ["input", "form", "results", "submitButton"];

  static classes = ["hidden", "loading"];

  static debounces = [{ name: "submit", wait: 300 }];

  combobox = null;

  prevValue = "";

  connect() {
    useDebounce(this);
  }

  initCombobox() {
    this.combobox = new Combobox(this.inputTarget, this.resultsTarget);
    this.combobox.start();
  }

  destroyCombobox() {
    this.combobox?.destroy();
    this.combobox = null;
  }

  submitButtonTargetConnected(target) {
    target.classList.add(this.hiddenClass);
  }

  inputTargetConnected() {
    if (!this.combobox && this.hasResultsTarget) {
      this.initCombobox();
    }
  }

  inputTargetDisconnected() {
    this.destroyCombobox();
  }

  resultsTargetConnected() {
    this.formTarget.classList.remove(this.loadingClass);
    if (!this.combobox && this.hasInputTarget) {
      this.initCombobox();
    }
  }

  resultsTargetDisconnected() {
    this.destroyCombobox();
  }

  /**
   * Prevents the `Enter` key from submitting the form.
   */
  // eslint-disable-next-line class-methods-use-this
  handleKeypress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  /**
   * Submits the search form when the input is valid, or clears the results div
   * if the input is invalid.
   */
  handleInput(e) {
    const { currentTarget } = e;
    const value = currentTarget.value.trim();

    if (value && this.prevValue === value) {
      return;
    }

    this.prevValue = value;

    if (currentTarget.checkValidity()) {
      this.formTarget.classList.add(this.loadingClass);
      this.submit();
    } else if (this.hasResultsTarget) {
      this.resultsTarget.replaceChildren();
      this.destroyCombobox();
    }
  }

  /**
   * Clears the search results when the input is cleared.
   */
  handleSearch() {
    this.prevValue = "";
    if (this.hasResultsTarget) {
      this.resultsTarget.replaceChildren();
      this.destroyCombobox();
    }
  }

  /**
   * Submits the search form.
   */
  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  /**
   * Resets the form when a search results is selected by keyboard or mouse.
   */
  handleComboBoxCommit() {
    if (this.hasInputTarget) {
      this.inputTarget.blur();
      this.inputTarget.value = "";
      this.prevValue = "";
    }
    if (this.hasResultsTarget) {
      this.resultsTarget.replaceChildren();
    }
    this.destroyCombobox();
  }
}
