import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

import { useEmailSubjectEditor } from "@bryq/src/behaviors/use_email_subject_editor";
import { useEmailTextEditor } from "@bryq/src/behaviors/use_email_text_editor";
import { deserialize } from "@bryq/src/utils/text_editor/serializers";

export default class extends Controller {
  static targets = [
    "body",
    "subject",
    "resetButton",
    "submitButton",
    "select",
    "selectContainer",
  ];

  static classes = ["hidden"];

  static values = {
    emailVariables: Array,
    defaultSubject: String,
    defaultBody: String,
  };

  /**
   * Checks if the `subject` and `body` fields are the default values.
   */
  get emailIsDefault() {
    const isDefault =
      this.subjectTarget.value === this.defaultSubjectValue &&
      this.bodyTarget.value.replace(/\n/g, "") ===
        this.defaultBodyValue.replace(/\n/g, "");
    return isDefault;
  }

  subjectTargetConnected(target) {
    this.subjectEditor = useEmailSubjectEditor(this, target, {
      variables: this.emailVariablesValue,
    });
  }

  bodyTargetConnected(target) {
    this.bodyEditor = useEmailTextEditor(this, target, {
      variables: this.emailVariablesValue,
    });
  }

  resetButtonTargetConnected(target) {
    target.classList.remove(this.hiddenClass);
    // eslint-disable-next-line no-param-reassign
    target.disabled = this.emailIsDefault;
  }

  /**
   * Sets up the SlimSelect instance.
   * The `selectContainer` target is used to position the dropdown since
   * SlimSelect does not know how to render in a fixed positon container.
   */
  selectTargetConnected(target) {
    this.select = new SlimSelect({
      select: target,
      settings: {
        contentLocation: this.selectContainerTarget,
        contentPosition: "relative",
        showSearch: false,
      },
      events: {
        beforeOpen: () => {
          this.selectContainerTarget.classList.remove(this.hiddenClass);
        },
        afterClose: () => {
          this.selectContainerTarget.classList.add(this.hiddenClass);
        },
        // Sets the submit on the SlimSelect's `afterChange` event to avoid a
        // new validation request when restoring values via discard, as there
        // is no way to stop SlimSelect from updating the original select again
        // when `setSelected` is called.
        afterChange: () => {
          this.submitForm();
        },
      },
    });
  }

  submitForm() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  /**
   * Filters out `previous-values-restore-end` events and submits the
   * assessment settings form if it was the assessment settings dialog fields
   * that were restored (this dialog was discarded).
   *
   * This controller is inside the dialog that is discarded, so the only way to
   * know is to do a `contains` check.
   *
   * It cannot be moved outside of the dialog as it needs to be refreshed when
   * the language changes and a new email template is brought in by the
   * template.
   */
  dialogDiscarded(e) {
    const { target } = e;
    if (target.contains(this.element)) {
      this.submitForm();
    }
  }

  /**
   * Enables or disables the reset button based on the subject and body values.
   */
  toggleResetButton() {
    this.resetButtonTarget.disabled = this.emailIsDefault;
  }

  /**
   * Resets the subject and body fields to their default values.
   */
  resetSubjectAndBody() {
    this.subjectEditor.commands.setContent(
      deserialize(this.defaultSubjectValue, this.emailVariablesValue),
      true,
    );
    this.bodyEditor.commands.setContent(
      deserialize(this.defaultBodyValue, this.emailVariablesValue),
      true,
    );
    this.toggleResetButton();
  }

  disconnect() {
    this.select.destroy();
  }
}
