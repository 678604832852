/**
 * Serializes Tiptap HTML to HTML the backend expects.
 *
 * Converts var nodes to {{key}} strings.
 */
export const serialize = (html, variables = []) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  variables.forEach((v) => {
    const vars = doc.querySelectorAll(`var[data-key="${v.key}"]`);
    vars.forEach((node) => {
      node.replaceWith(`{${v.key}}`);
    });
  });
  return doc.body.innerHTML;
};

/**
 * Parses backend HTML to HTML Tiptap expects.
 *
 * Converts {{key}} strings to var nodes.
 */
export const deserialize = (value, variables = []) => {
  let html = value;

  variables.forEach((v) => {
    const regexp = new RegExp(`{${v.key}}`, "g");
    html = html.replace(
      regexp,
      `<var data-key="${v.key}" data-kind="${v.kind}">${v.label}</var>`,
    );
  });

  return html;
};
