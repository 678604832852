import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

import { useEmailSubjectEditor } from "@bryq/src/behaviors/use_email_subject_editor";
import { useEmailTextEditor } from "@bryq/src/behaviors/use_email_text_editor";

export default class extends Controller {
  static targets = [
    "language",
    "assessmentKind",
    "body",
    "subject",
    "changeLanguageSubmitButton",
  ];

  static classes = ["hidden"];

  static values = { emailVariables: Array };

  connect() {
    useEmailSubjectEditor(this, this.subjectTarget, {
      variables: this.emailVariablesValue,
    });

    useEmailTextEditor(this, this.bodyTarget, {
      variables: this.emailVariablesValue,
    });

    if (this.hasLanguageTarget) {
      this.languageSelect = new SlimSelect({
        select: this.languageTarget,
        settings: {
          showSearch: false,
        },
      });
    }

    if (this.hasChangeLanguageSubmitButtonTarget) {
      this.changeLanguageSubmitButtonTarget.classList.add(this.hiddenClass);
    }

    if (this.hasAssessmentKindTarget) {
      // TODO: consider moving this to custom select wdiget on the Django side.
      Array.from(this.assessmentKindTarget.options).forEach((option) => {
        const { innerText } = option;
        const parts = innerText.split(" - ");
        const html = parts.reduce((acc, part, index) => {
          if (index === 0) {
            return `${acc}<strong>${part}</strong>`;
          }
          return `${acc}<br/>${part}`;
        }, "");
        // eslint-disable-next-line no-param-reassign
        option.dataset.html = `<div>${html}</div>`;
      });

      this.assessmentKindSelect = new SlimSelect({
        select: this.assessmentKindTarget,
        settings: {
          showSearch: false,
        },
      });
    }
  }

  disconnect() {
    if (this.languageSelect) {
      this.languageSelect.destroy();
    }
    if (this.assessmentKindSelect) {
      this.assessmentKindSelect.destroy();
    }
  }

  /**
   * This method should be called when the language select changes. It will
   * trigger a click on the change language submit button to reload the form
   * with the selected language.
   * @param {Event} event
   */
  submitLanguage() {
    if (this.hasChangeLanguageSubmitButtonTarget) {
      this.changeLanguageSubmitButtonTarget.click();
    }
  }

  /**
   * This method filters the submit events from the form validation and
   * dispatches the `main-submit-start` event that then can be listened to by
   * other controllers interested in the fomr submission such as the
   * `LoaderOverlay` controller.
   * @param {Event} event
   */
  submitStart(event) {
    const submitterName = event.detail.formSubmission.submitter.name;
    if (submitterName === "_validate_form") {
      return;
    }
    this.dispatch("main-submit-start", event);
  }

  /**
   * This method filters the submit events from the form validation and
   * dispatches the main-submit-end event that then can be listened to by other
   * controllers interested in the fomr submission such as the `LoaderOverlay`
   * controller.
   * @param {Event} event
   */
  submitEnd(event) {
    const { detail: { success, formSubmission = {} } = {} } = event;
    const { submitter = {} } = formSubmission;
    const { name: submitterName } = submitter;

    const skipDispatch =
      submitterName === "_validate_form" ||
      submitterName === "_change_language";

    if (skipDispatch) {
      return;
    }
    this.dispatch("main-submit-end", event);

    if (success) {
      this.dispatch("form-submitted-with-success");
    }
  }
}
