import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = ["select", "submitButton"];

  static classes = ["hidden", "slimSelect"];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }

    // Creates the SlimSelect object and makes sure the original select gets
    // updated when the SlimSelect value changes - allows to the behaviour of
    // the original select as a fallback if there is a problem with the
    // SlimSelect.
    if (this.hasSelectTarget) {
      // eslint-disable-next-line no-new
      this.statusSelect = new SlimSelect({
        select: this.selectTarget,
        settings: {
          showSearch: false,
        },
      });
    }
  }

  disconnect() {
    // Destroys the SlimSelect object when the controller is disconnected to
    // prevent memory leaks related to event listeners.
    if (this.statusSelect) {
      this.statusSelect.destroy();
    }
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }
}
