import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

import { IS_WEBAPP } from "@bryq/src/settings";

export default class extends Controller {
  static targets = ["select", "submitButton"];

  static classes = ["hidden", "slimSelect"];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }

    // Creates the SlimSelect object and makes sure the original select gets
    // updated when the SlimSelect value changes - allows to the behaviour of
    // the original select as a fallback if there is a problem with the
    // SlimSelect.
    if (this.hasSelectTarget) {
      // eslint-disable-next-line no-new
      this.jobSelect = new SlimSelect({
        select: this.selectTarget,
        settings: {
          showSearch: false,
        },
      });
    }
  }

  disconnect() {
    // Destroys the SlimSelect object when the controller is disconnected to
    // prevent memory leaks related to event listeners.
    if (this.jobSelect) {
      this.jobSelect.destroy();
    }
  }

  submit(event) {
    /**
     * Hack to make the job select work on the webapp. To be removed when the
     * webapp is retired.
     */
    if (IS_WEBAPP) {
      event.preventDefault();

      const { __reactRouter: reactRouter } = window;

      const { href } = window.location;
      const url = new URL(href);
      url.searchParams.delete("page");
      url.searchParams.set("job", this.selectTarget.value);
      const to = url.href.replace(url.origin, "");

      // Prevents the router from navigating to the same page multiple times in
      // case the user clicks the submit button multiple times.
      const currentTo = `${reactRouter.state.location.pathname}${reactRouter.state.location.search}`;

      if (currentTo === to) {
        return;
      }

      reactRouter.navigate(to);
      return;
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }
}
