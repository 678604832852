import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton"];

  static classes = ["hidden"];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }
}
