import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trait"];

  /**
   * Sets the target's `display` to `none` when its width is 0.
   */
  // eslint-disable-next-line class-methods-use-this
  traitTargetConnected(target) {
    const {
      dataset: { name },
    } = target;
    const value = parseInt(
      this.element.style.getPropertyValue(`--cognitive-${name}-weight`),
      10,
    );
    target.style.setProperty("display", value > 0 ? "block" : "none");
  }

  /**
   * Sets the trait CSS variables.
   * Toggles display depending on the value.
   */
  updateVizualisation({ detail }) {
    Object.entries(detail).forEach(([trait, value]) => {
      this.element.style.setProperty(`--cognitive-${trait}-weight`, value);
      const target = this.traitTargets.find((t) => t.dataset.name === trait);
      target.style.setProperty("display", value > 0 ? "block" : "none");
    });
  }
}
