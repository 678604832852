import { Controller } from "@hotwired/stimulus";

/**
 * A controller that updates the `--current-value` CSS variable to reflect the
 * current value of the input range slider.
 *
 * This is used to style the input's track with a gradient, since Chrome does
 * not support the `::range-lower` pseudo-element.
 *
 * To use:
 * 1. Make sure your input is of `type="range"`, has the ".slider" class, and
 *    lives in a ".b-form" class form.
 * 2. Add this controller to one of the input elements parents:
 *      `data-controller="utils--input-range-slider"`.
 * 3. Add the `input` as a target `data-utils--input-range-slider-target="input"`
 * 4. Add the `input` event, or any other proxy event that has the target and
 *    its value, to the input element:
 *      `data-action="input->utils--input-range-slider#handleInput"`.
 *
 * You can optionally assign an `output` target, which will display the current
 * value.
 */
export default class extends Controller {
  static targets = ["input", "output"];

  // eslint-disable-next-line class-methods-use-this
  inputTargetConnected(target) {
    target.style.setProperty("--current-value", `${target.value}%`);
  }

  outputTargetConnected(target) {
    if (this.hasInputTarget) {
      // eslint-disable-next-line no-param-reassign
      target.textContent = `${this.inputTarget.value}%`;
    }
  }

  handleInput(e) {
    const {
      target: { value },
    } = e;

    if (this.hasInputTarget) {
      this.inputTarget.style.setProperty("--current-value", `${value}%`);
    }

    if (this.hasOutputTarget) {
      this.outputTarget.textContent = `${value}%`;
    }
  }
}
