import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["answers", "showAnswersButton", "hideAnswersButton"];

  static classes = ["hidden"];

  connect() {
    this.hideAnswers();
  }

  showAnswers() {
    this.answersTarget.classList.remove(this.hiddenClass);
    this.showAnswersButtonTarget.classList.add(this.hiddenClass);
    this.hideAnswersButtonTarget.classList.remove(this.hiddenClass);
  }

  hideAnswers() {
    this.answersTarget.classList.add(this.hiddenClass);
    this.showAnswersButtonTarget.classList.remove(this.hiddenClass);
    this.hideAnswersButtonTarget.classList.add(this.hiddenClass);
  }
}
