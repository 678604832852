import { Controller } from "@hotwired/stimulus";

/**
 * Controller that allows to open and close a modal.
 * The modal is structured as follows:
 * `overlay`
 *  -> `wrapper`
 *    -> `container`
 *      -> `header`
 *      -> `body`
 *      -> `footer`
 *
 *
 * Modal can work in 2 modes:
 * 1. by copying the content of a template alreayd in the page into the modal
 * 2. by getting the content from a remote url and injecting it into the modal
 *   via the turbo frame mechanism.
 *
 * Examples:
 * - Case 1:
 * ```html
 *  <button data-action="click->utils--modal#openModal"
 *          data-utils--modal-content-template-id-param="modal-content-for-anonymized-candidate-results">
 *    {% svg "img/icons/actions/anonymise_candidate.svg" %}
 *  </button>
 *  <div id="modal-content-for-anonymized-candidate-results"
 *       class="b-modal-content-template">
 *    <div class="b-modal-container">
 *       The content of the modal
 *    </div>
 *  </div>
 * ```
 *
 * The `data-utils--modal-content-template-id-param` is used to pass the
 * id the DOM element representing the modal content template whose contnet
 * will be cloned and injected in the modal.
 *
 * Use the `:prevent` modifier to prevent the default behavior of the event.
 * For example: `<button data-action="click->modal#openModal:prevent">Open modal</button>`
 * This is not done by default because it would prevent loading turbo frames
 * opening when the user clicks on a link.
 *
 * - Case 2:
 * ```html
 *
 *  <a href="{{ a_url }}"
 *     data-turbo-frame="modal-content"
 *     data-action="utils--modal#openOverlay turbo:fetch-request-error->utils-modal#closeMoal">
 *    Click me
 *  </a>
 * ```
 * - The `data-turbo-frame` attribute is used to tell turbo where to inject
 *   the content of the remote url.
 * - The returned content will be injected in the modal content.
 * - The `data-action` attribute is used:
 *   1. to tell turbo to open the modal overlay tthat contains the loader as
 *      soon as the request is sent.
 *   2. to tell turbo to close the modal overlay if the request fails.
 *
 * The `data-action` attribute on the `turbo-frame` element for the modal
 * content is used to open the modal overlay when the content is loaded and
 * the frame is rendered.
 *
 * If you only want to open the overlay with the loader, use the `openOverlay`
 * action instead.
 */
export default class extends Controller {
  static targets = [
    "modalOverlay",
    "modalLoader",
    "modalWrapper",
    "modalContent",
  ];

  static classes = ["closedModal"];

  openOverlay() {
    this.modalOverlayTarget.classList.remove(this.closedModalClass);
    if (this.hasModalLoaderTarget) {
      this.modalLoaderTarget.classList.remove(this.closedModalClass);
    }
  }

  /**
   * Opens the modal.
   */
  openModal(event) {
    const { params: { contentTemplateId } = {} } = event;
    if (contentTemplateId) {
      this.modalContentTarget.innerHTML = "";
      const template = document.getElementById(contentTemplateId);
      const content = template.firstElementChild.cloneNode(true);
      this.modalContentTarget.appendChild(content);
    }
    this.openOverlay(event);
    this.modalWrapperTarget.classList.remove(this.closedModalClass);
  }

  closeModal(event) {
    const { params: { preventEventDefault } = {} } = event;
    if (
      !this.modalOverlayTarget.classList.contains(this.closedModalClass) &&
      preventEventDefault !== false
    ) {
      event.preventDefault();
    }

    this.modalOverlayTarget.classList.add(this.closedModalClass);

    if (this.hasModalLoaderTarget) {
      this.modalLoaderTarget.classList.add(this.closedModalClass);
    }

    this.modalWrapperTarget.classList.add(this.closedModalClass);
  }
}
