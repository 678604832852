import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["errorList"];

  static classes = ["hasErrors", "hidden"];

  resetErrorState() {
    this.element.classList.remove(this.hasErrorsClass);

    if (this.hasErrorListTarget) {
      this.errorListTarget.classList.add(this.hiddenClass);
    }
  }
}
