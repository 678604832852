import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "radioInput"];

  static classes = ["hidden", "labelActive", "submitting"];

  connect() {
    this.submitButtonTarget.classList.add(this.hiddenClass);
  }

  submit(event) {
    event.currentTarget.parentElement.classList.add(this.labelActiveClass);
    this.element.classList.add(this.submittingClass);
    this.submitButtonTarget.click();
  }

  radioInputTargetConnected(target) {
    if (target.checked) {
      target.parentElement.classList.add(this.labelActiveClass);
    }
  }

  removeBanner() {
    this.element.remove();
  }
}
