import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["infoIcon", "infoTooltipTemplate"];

  connect() {
    tippy(this.infoIconTarget, {
      appendTo: () => document.body, // to ensure it's not clipped by the table
      // when there are less than 3 rows.
      content: this.infoTooltipTemplateTarget,
      theme: "large",
      interactive: true,
      placement: "bottom",
      delay: [120, 120],
      maxWidth: 428,
    });
  }
}
