/* eslint import/prefer-default-export: "off" */
import { readJsonScriptValue } from "@bryq/src/utils/json_script";

export const IS_CYRPRESS = readJsonScriptValue("settings-is-cypress", {
  throws: false,
  defaultValue: false,
});

export const IS_WEBAPP = !!window.IS_WEBAPP;

// Some settinggs are only available in this context and not in the React app
// context. In these cases, the `readJsonScriptValue` should only throw an error
// in the employer app context, not in the React app context.
const shouldThrow = IS_WEBAPP === false;

export const APP_ENV = readJsonScriptValue("settings-app-env", {
  throws: shouldThrow,
});
export const APP_TARGET = readJsonScriptValue("settings-app-target", {
  throws: shouldThrow,
});

export const SENTRY_DSN_FRONTEND = readJsonScriptValue(
  "settings-sentry-dsn-frontend",
  {
    throws: false, // We don't want Sentry to initialize in the employer app
    // context localy and in the React app context.
    defaultValue: "",
  },
);

export const GIT_REF = readJsonScriptValue("settings-git-ref", {
  throws: shouldThrow,
  defaultValue: "",
});

export const PUSHER_KEY = readJsonScriptValue("settings-pusher-key", {
  throws: shouldThrow,
});

export const PUSHER_AUTHENTICATION_URL = readJsonScriptValue(
  "settings-pusher-authentication-url",
  { throws: shouldThrow },
);
