import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "cancelButton",
    "startButton",
    "checkbox",
    "selectAll",
    "panel",
    "count",
    "slot",
    "submitButton",
    "hideOnCompare",
  ];

  static classes = ["hidden"];

  static values = { url: String };

  // The maximum number of items that can be compared.
  maxItems = 0;

  // The current items being compared.
  items = [];

  hasStartedComparing = false;

  connect() {
    if (this.hasStartButtonTarget) {
      this.startButtonTarget.classList.remove(this.hiddenClass);
    }

    this.maxItems = this.slotTargets.length;
    this.setItems([]);
  }

  checkboxTargetConnected(target) {
    if (this.hasStartedComparing) {
      target.classList.remove(this.hiddenClass);
    } else {
      target.classList.add(this.hiddenClass);
    }
  }

  hideOnCompareTargetConnected(target) {
    if (this.hasStartedComparing) {
      target.classList.add(this.hiddenClass);
    } else {
      target.classList.remove(this.hiddenClass);
    }
  }

  /**
   * Sets new items and rerenders.
   *
   * No one else should call `this.render`.
   */
  setItems(newItems) {
    this.items = newItems;
    this.render();
  }

  /**
   * Displays the compare elements and switches the start/cancel buttons.
   *
   * Clears any selected items.
   */
  startCompare() {
    this.hasStartedComparing = true;

    this.setItems([]);

    if (this.hasStartButtonTarget) {
      this.startButtonTarget.classList.add(this.hiddenClass);
    }

    if (this.hasCancelButtonTarget) {
      this.cancelButtonTarget.classList.remove(this.hiddenClass);
    }

    if (this.hasSelectAllTarget) {
      this.selectAllTarget.classList.remove(this.hiddenClass);
    }

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.classList.remove(this.hiddenClass);
    });

    this.hideOnCompareTargets.forEach((target) => {
      target.classList.add(this.hiddenClass);
    });

    if (this.hasPanelTarget) {
      this.panelTarget.classList.remove(this.hiddenClass);
    }
  }

  /**
   * Hides the compare elements and switches the start/cancel buttons.
   *
   * Clears any selected items.
   */
  cancelCompare() {
    this.hasStartedComparing = false;

    this.setItems([]);

    if (this.hasStartButtonTarget) {
      this.startButtonTarget.classList.remove(this.hiddenClass);
    }

    if (this.hasCancelButtonTarget) {
      this.cancelButtonTarget.classList.add(this.hiddenClass);
    }

    if (this.hasSelectAllTarget) {
      this.selectAllTarget.classList.add(this.hiddenClass);
    }

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.classList.add(this.hiddenClass);
    });

    this.hideOnCompareTargets.forEach((target) => {
      target.classList.remove(this.hiddenClass);
    });

    if (this.hasPanelTarget) {
      this.panelTarget.classList.add(this.hiddenClass);
    }
  }

  /**
   * Toggles an item being compared or not and rerenders the panel.
   */
  toggleComparing(e) {
    const {
      currentTarget: {
        dataset: { label },
        value,
      },
    } = e;

    const isComparing = this.items.some((item) => item.value === value);

    if (isComparing) {
      this.setItems(this.items.filter((item) => item.value !== value));
    } else {
      this.setItems([...this.items, { label, value }]);
    }
  }

  /**
   * Clears all selected items.
   */
  deselectAll(e) {
    e.preventDefault();
    this.setItems([]);
  }

  /**
   * Renders or rerenders the panel content based on `this.items`.
   *
   * Enables/disables the submitButton based on the number of selected items.
   *
   * Enables/disables the checkboxes based on the number of selected items.
   */
  render() {
    const numberOfItems = this.items.length;

    if (this.hasCountTarget) {
      this.countTarget.innerHTML = numberOfItems;
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = numberOfItems < 2;
    }

    if (this.hasSelectAllTarget) {
      this.selectAllTarget.disabled = numberOfItems === 0;
      this.selectAllTarget.checked = false;
      this.selectAllTarget.indeterminate = numberOfItems > 0;
    }

    this.checkboxTargets.forEach((checkbox) => {
      // eslint-disable-next-line no-param-reassign
      checkbox.checked = this.items.some(
        (item) => item.value === checkbox.value,
      );
      // eslint-disable-next-line no-param-reassign
      checkbox.disabled =
        checkbox.dataset.stayDisabled === "true" ||
        (!checkbox.checked && numberOfItems >= this.maxItems);
    });

    // Clears or repopulates the slot targets as required.
    this.slotTargets.forEach((slot, i) => {
      const item = this.items[i];
      const span = slot.querySelector("span");
      const button = slot.querySelector("button");
      if (item !== undefined) {
        span.innerHTML = item.label;
        button.removeAttribute("disabled");
        button.setAttribute("value", item.value);
      } else {
        span.innerHTML = "";
        button.setAttribute("disabled", "");
        button.removeAttribute("value");
      }
    });
  }

  /**
   * Dispatches a `compare-selection-end` event which will trigger
   * `data-utils--react-links#navigateTo`.
   *
   * This will navigate the user to the comparison URL, using either
   * react-router or normal fallback navigation.
   */
  submit(e) {
    e.preventDefault();
    const ids = this.items.map((item) => item.value).join(",");
    const uri = [this.urlValue, ids].join("");
    this.dispatch("compare-selection-end", {
      detail: {
        to: uri,
        turboFallbackTo: uri,
        turboFallbackFrame: "main-content",
      },
    });
  }

  disconnect() {
    this.cancelCompare();
  }
}
