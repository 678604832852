/* eslint import/prefer-default-export: "off" */
import { Variable } from "./variable";

/**
 * Extends Variable with a Bool node that behaves exactly the same, except is
 * block level, and is not editable.
 */
export const Bool = Variable.extend({
  name: "bool",

  draggable: false,

  group: "block",

  inline: false,

  selectable: false,

  addCommands() {
    return {
      // Inserts the bool node with the give key at the end of the document.
      enableBoolean:
        (key) =>
        ({ commands, state }) => {
          const item = this.options.choices.find(
            (choice) => choice.key === key,
          );
          commands.insertContentAt(state.doc.content.size, {
            type: "bool",
            attrs: item,
          });
        },
      // Removes all the bool nodes with key from the document.
      disableBoolean:
        (key) =>
        ({ commands, editor }) => {
          const json = editor.getJSON();
          commands.setContent(
            {
              ...json,
              content: json.content.filter(
                (node) => node.type !== "bool" || node?.attrs?.key !== key,
              ),
            },
            { emitUpdate: true },
          );
        },
    };
  },
});
