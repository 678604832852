/* eslint import/prefer-default-export: "off" */

/**
 * Allows a controller to use advanced pagination behavior (infinite scroll)
 * instead of the basic one.
 * The advanced pagination is using a `<turbo-frame>` with lazy loading as a
 * waypoint.
 * The controller must define the following targets:
 * - `basicPagination`: the basic pagination links container.
 * - `advancedPagination`: the turbo frame container.
 * - `loaderForPagination`: the loader element to display during the pagination.
 * The controller must define the following classes:
 * - `hiddenPagination`: the class to hide the pagination loader.
 * When connecting the controller, the behavior will hide the basic pagination
 * and display the advanced pagination.
 * @param {Controller} controller
 * @returns {void}
 */
export const useTurboFrameWaypointPagination = (controller) => {
  // Displays the advanced pagination (infitine scroll) instead of the basic one.
  if (controller.hasBasicPaginationTarget) {
    controller.basicPaginationTarget.classList.add(
      controller.hiddenPaginationClass,
    );
  }
  if (controller.hasAdvancedPaginationTarget) {
    controller.advancedPaginationTarget.classList.remove(
      controller.hiddenPaginationClass,
    );
  }

  Object.assign(controller, {
    showLoaderForPagination(event) {
      // Trick the turbo-frame to retrieve 2 turbo-streams (list content and
      // pagination) instead of retrieving a frame. This is a workaround to
      // avoid settings manually a waypoint in the page.
      // eslint-disable-next-line no-param-reassign
      event.detail.fetchOptions.headers.Accept =
        "text/vnd.turbo-stream.html, text/html, application/xhtml+xml";
      if (controller.hasLoaderForPaginationTarget) {
        controller.loaderForPaginationTarget.classList.remove(
          controller.hiddenPaginationClass,
        );
      }
    },
  });
};
