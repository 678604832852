import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static defaultTitle = "Bryq";

  static values = {
    title: String,
  };

  connect() {
    setTimeout(() => {
      document.title = this.hasTitleValue
        ? `${this.titleValue} - Bryq`
        : this.defaultTitle;
    }, 0);
  }
}
