/* eslint import/prefer-default-export: "off" */

class MultiEmailInput {
  seperator = /[\s,]+/;

  value = "";

  constructor(controller, target, options = { errorClass: "has-errors" }) {
    this.controller = controller;
    this.target = target;
    this.options = options;

    this.disabled = this.target.disabled;

    this.originalDisplay = this.target.style.display;

    this.container = document.createElement("div");
    this.container.setAttribute("tabindex", "-1");
    this.container.classList.add("multi-email-input-container");
    if (this.disabled) {
      this.container.classList.add("disabled");
    }

    this.input = document.createElement("input");
    this.input.setAttribute("type", "email");
    this.input.setAttribute("multiple", true);
    if (this.disabled) {
      this.input.setAttribute("disabled", "");
    }
    this.input.classList.add("multi-email-input-field");
    // Copies the target's `data-action` attribute.
    // eslint-disable-next-line no-param-reassign
    this.input.dataset.action = this.target.dataset.action;

    this.container.appendChild(this.input);
    this.target.parentNode.insertBefore(this.container, this.target);

    this.onFocus = this.handleFocus.bind(this);
    this.container.addEventListener("focus", this.onFocus);

    this.onInputChange = this.handleInputChange.bind(this);
    this.input.addEventListener("input", this.onInputChange);

    this.onInputKeyDown = this.handleInputKeyDown.bind(this);
    this.input.addEventListener("keydown", this.onInputKeyDown);

    this.onInputBlur = this.handleInputBlur.bind(this);
    this.input.addEventListener("blur", this.onInputBlur);

    this.onDeleteClick = this.handleDeleteClick.bind(this);

    // Sets the initial value.
    this.setValue(
      (this.target.value?.trim() || "")
        .split(this.seperator)
        .map((email) => email.trim()),
    );

    this.target.style.display = "none";
  }

  /**
   * Sets the value of the input field.
   */
  setInputValue(value) {
    this.input.value = value;
  }

  /**
   * Sets the overall value in the state and updates the UI.
   * Updates the target's value when done.
   */
  setValue(newValue) {
    this.clearChips();
    this.value = Array.from(new Set(newValue))
      .map((email) => email.trim())
      .filter(Boolean);

    if (this.value.length === 0) {
      this.input.setAttribute("placeholder", this.target.placeholder);
    } else {
      this.input.setAttribute("placeholder", "");
    }

    this.value.forEach((email) => {
      const chip = document.createElement("div");
      chip.append(email);
      chip.classList.add("multi-email-input-chip");
      const button = document.createElement("button");
      button.setAttribute("value", email);
      button.setAttribute("type", "button");
      if (this.disabled) {
        button.setAttribute("disabled", "");
      }
      button.classList.add("multi-email-input-delete");
      button.addEventListener("click", this.onDeleteClick);
      chip.append(button);
      this.container.insertBefore(chip, this.input);
    });
    this.target.value = this.value.join(",");
  }

  /**
   * Focuses the input when the container is focused.
   */
  handleFocus() {
    if (document.activeElement !== this.input) {
      this.input.focus();
    }
  }

  /**
   * Checks if there is a valid email and adds it to the array on blur.
   */
  handleInputBlur() {
    if (this.input.checkValidity()) {
      this.setValue([...this.value, this.input.value.trim()]);
      this.setInputValue("");
    }
  }

  /**
   * Adds the current input value to the array of values on space, tab, and
   * enter.
   *
   * Deletes the last value in the array on backspace.
   */
  handleInputKeyDown(e) {
    const inputValue = e.target.value.trim();
    const triggers = new Set([9, 13, 32, 188]);
    if (triggers.has(e.keyCode)) {
      e.preventDefault();
      if (inputValue && this.input.checkValidity()) {
        this.setInputValue("");
        this.setValue([...this.value, inputValue]);
      }
    } else if (
      e.keyCode === 8 &&
      this.value.length > 0 &&
      e.target.value === ""
    ) {
      this.setValue(this.value.slice(0, this.value.length - 1));
    }
  }

  /**
   * Adds any valid emails from the input to the array, and clears the input.
   * Useful for when a list of emails is pasted.
   */
  handleInputChange(e) {
    const {
      target: { value },
    } = e;
    const items = value.trim().split(this.seperator);
    if (this.input.checkValidity()) {
      this.container.classList.remove(this.options.errorClass);
      if (items.length > 1) {
        this.setValue([...this.value, ...items]);
        this.setInputValue("");
      }
    } else {
      this.container.classList.add(this.options.errorClass);
    }
  }

  /**
   * Handles the delete button on a chip being clicked, removing the email from
   * this.value.
   */
  handleDeleteClick(e) {
    this.setValue(this.value.filter((email) => email !== e.target.value));
    this.input.focus();
  }

  /**
   * Removes the current chips and their event listeners.
   */
  clearChips() {
    this.container
      .querySelectorAll(".multi-email-input-chip")
      .forEach((chip) => {
        const button = chip.querySelector("button");
        button?.removeEventListener("click", this.onDeleteClick);
        chip.remove();
      });
  }

  /**
   * Removes event listeners and DOM elements, and returns the target to its
   * original state.
   */
  destroy() {
    this.clearChips();
    this.input.removeEventListener("blur", this.onInputBlur);
    this.input.removeEventListener("keydown", this.onInputKeyDown);
    this.input.removeEventListener("input", this.onInputChange);
    this.container.removeEventListener("focus", this.onFocus);
    this.input.remove();
    this.container.remove();
    this.target.style.display = this.originalDisplay;
  }
}

export const useMultiEmailInput = (
  controller,
  target,
  options = { errorClass: "has-errors" },
) => {
  const multiEmailInput = new MultiEmailInput(controller, target, options);

  // Keeps a copy of the current disconnect() function of the controller to
  // support composing several behaviors.
  const controllerDisconnect = controller.disconnect.bind(controller);

  // Cleans up when the controller disconnects.
  Object.assign(controller, {
    disconnect() {
      multiEmailInput.destroy();
      controllerDisconnect();
    },
  });

  return multiEmailInput;
};
