/* eslint import/prefer-default-export: "off" */

/**
 * This behavior will ensure that the url gets updated after a filter is
 * applied on a view.
 * It will also make the navigation back to the page with the same filter
 * applied possible. However it does not allow the navigation between different
 * filtered state of the same page.
 */
export const useUpdateWindowLocationSearch = (controller) => {
  let urlEncodedQueryStringValue;

  if (controller.getNewUrlEncodedQueryStringValue === undefined) {
    urlEncodedQueryStringValue = controller.urlEncodedQueryStringValue;
  } else {
    urlEncodedQueryStringValue = controller.getNewUrlEncodedQueryStringValue();
  }

  // Create fake history to have a better behavior on the back/forward
  // history buttons.
  const oldUrlString = window.location.href;
  const baseUrl = new URL(oldUrlString.split("?")[0]);
  const newUrlString = `${baseUrl.toString()}?${urlEncodedQueryStringValue}`;

  if (newUrlString !== oldUrlString) {
    window.history.replaceState({}, null, newUrlString);
    window.Turbo.navigator.history.replace(new URL(newUrlString));
  }
};
