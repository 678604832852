import { connectStreamSource, disconnectStreamSource } from "@hotwired/turbo";

import {
  APP_ENV,
  IS_WEBAPP,
  PUSHER_AUTHENTICATION_URL,
  PUSHER_KEY,
} from "@bryq/src/settings";

if (APP_ENV === "local") {
  window.Pusher.logToConsole = true;
}

let pusher;
if (IS_WEBAPP === false) {
  pusher = new window.Pusher(PUSHER_KEY, {
    cluster: "eu",
    channelAuthorization: {
      endpoint: PUSHER_AUTHENTICATION_URL,
    },
  });
}

/**
 * A custom element that connects to a Pusher channel and dispatches
 * `MessageEvent` events when a `stream` event is received.
 * The stream event is expected to have a the HTML content of the stream as the
 * event data.
 *
 * ```python
 * >>> import pusher
 * ... pusher_client = pusher.Pusher(
 * ...   app_id='<app_id>',
 * ...   key='<app_key>',
 * ...   secret='<app_secret>',
 * ...   cluster='eu',
 * ...   ssl=True
 * ... )
 * >>> stream = '''
 * ... <turbo-stream action="append" target="messages-list">
 * ...   <template>
 * ...     <li class="message success body-medium-bold color-neutral-90"
 * ...         data-messages-target="message"
 * ...         data-action="animationend->messages#removeMessage">
 * ...         Hello world
 * ...     </li>
 * ...   </template>
 * ... </turbo-stream>
 * ... '''
 * >>> pusher_client.trigger('<channel_name>', 'turbo_stream', stream)
 * {}
 * ```
 *  @element turbo-pusher-stream-source
 *  @attr {String} channel - The name of the Pusher channel to connect to.
 */
class TurboPusherStreamSourceElement extends HTMLElement {
  async connectedCallback() {
    connectStreamSource(this);
    // When used in the react app, the pusher client need to access the react
    // auth servive and set the `X-Turbo-Authorization` header. This is not
    // possible in this context, so the React app will define its own pusher
    // client and make it available in the window object so we can use it here.
    const pusherClient = IS_WEBAPP === false ? pusher : window.bryqPusherClient;

    this.channel = pusherClient.subscribe(this.channelName);
    this.channel.bind("turbo_stream", this.dispatchMessageEvent.bind(this));
  }

  disconnectedCallback() {
    disconnectStreamSource(this);
    this.channel.unsubscribe();
  }

  dispatchMessageEvent(data) {
    const event = new MessageEvent("message", { data });
    return this.dispatchEvent(event);
  }

  get channelName() {
    const channelName = this.getAttribute("channel-name");
    return channelName;
  }
}

customElements.define(
  "turbo-pusher-stream-source",
  TurboPusherStreamSourceElement,
);
