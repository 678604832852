import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["latestPageNumber"];

  /**
   * Returns the latest page number from the `latestPageNumber` target.
   */
  get latestPageNumber() {
    return this.latestPageNumberTarget.innerHTML.trim();
  }

  /**
   * Injects `latest_page_number` into the fetch options, except for the
   * exluded frames.
   */
  injectLatestPageNumber(event) {
    const exclude = [
      "application-list-filter-form",
      "application-list-pagination-waypoint",
    ];
    const {
      detail: {
        fetchOptions: { headers },
        url,
      },
    } = event;
    if (exclude.includes(headers["Turbo-Frame"])) {
      return;
    }

    url.searchParams.delete("page");
    url.searchParams.set("latest_page_number", this.latestPageNumber);
  }

  /**
   * Appends `latest_page_number` to a link's href's `next` query parameter.
   */
  appendLatestPageNumber(event) {
    // Take next, fuck with it, put it back in.
    const { currentTarget } = event;
    const url = new URL(currentTarget.href);

    let next = url.searchParams.get("next");
    next = decodeURIComponent(next);
    next = new URL(next);
    next.searchParams.delete("page");
    next.searchParams.set("latest_page_number", this.latestPageNumber);

    url.searchParams.set("next", next.href);
    currentTarget.href = url.href;
  }
}
