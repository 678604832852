/* eslint import/prefer-default-export: "off" */
/**
 * The functions below have been ported from the `stimulus-use` package because
 * they are not exported in the package's public API.
 */

/**
 * Returns the method of a controller or an empty function if the method does
 * not exist to avoid errors.
 * @param {Controller} controller The Stimulus controller.
 * @param {string} methodName
 * @returns {Function}
 */
export const getMethodFromController = (controller, methodName) => {
  const method = controller[methodName];
  if (typeof method === "function") {
    return method;
  }

  // eslint-disable-next-line no-console
  console.warn(
    `The method "${methodName}" is not defined in the controller "${controller.identifier}".`,
  );
  return (...args) => {}; // eslint-disable-line no-unused-vars
};

/**
 * Extends an event with a detail object so it can be used as a Stimulus event.
 * @param {string} type
 * @param {Event|null} event
 * @param {object} detail
 * @returns {CustomEvent}
 */
export const extendEvent = (type, event, detail) => {
  const { bubbles, cancelable, composed } = event || {
    bubbles: true,
    cancelable: true,
    composed: true,
  };

  if (event) {
    Object.assign(detail, { originalEvent: event });
  }

  const customEvent = new CustomEvent(type, {
    bubbles,
    cancelable,
    composed,
    detail,
  });
  return customEvent;
};
