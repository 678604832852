import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static targets = ["select", "submitButton"];

  static classes = ["hidden"];

  static debounces = [{ name: "handleSearch", wait: 250 }];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }

    if (this.hasSelectTarget) {
      // Marks the first option as the placeholder.
      this.selectTarget.querySelector(
        "option:first-child",
      ).dataset.placeholder = "true";

      this.statusSelect = new SlimSelect({
        select: this.selectTarget,
        settings: { allowDeselect: true, showSearch: true },
      });
    }

    useDebounce(this);
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  handleSearch() {
    this.submit();
  }

  disconnect() {
    if (this.statusSelect) {
      this.statusSelect.destroy();
    }
  }
}
