import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "container", "table", "row"];

  static classes = ["hidden"];

  static values = {
    companyId: String,
  };

  initialize() {
    this.storageKey = `application-list-column-selector-columns:${this.companyIdValue}`;
    this.current = JSON.parse(localStorage.getItem(this.storageKey) || "{}");
  }

  containerTargetConnected(containerTarget) {
    containerTarget.classList.remove(this.hiddenClass);
  }

  containerTargetDisconnected(containerTarget) {
    containerTarget.classList.add(this.hiddenClass);
  }

  /**
   * Toggles the checkboxes based on this.current, but also sets this.current
   * based on the initial state of any checkboxes it was missing.
   */
  checkboxTargetConnected(cb) {
    if (cb.name in this.current) {
      // eslint-disable-next-line no-param-reassign
      cb.checked = this.current[cb.name];
      this.toggleColumn(cb.name, cb.checked);
    } else {
      this.current[cb.name] = cb.checked;
    }
  }

  tableTargetConnected(tableTarget) {
    this.headers = Array.from(tableTarget.querySelectorAll("th"));
    this.headers.forEach((th) => {
      const { id } = th;
      const visible = this.current[id] !== false;
      if (visible) {
        th.style.removeProperty("display");
      } else {
        // eslint-disable-next-line no-param-reassign
        th.style.display = "none";
      }
    });
  }

  rowTargetConnected(row) {
    const cells = row.querySelectorAll("td");
    cells.forEach((cell, i) => {
      const { id } = this.headers[i];
      const visible = this.current[id] !== false;
      if (visible) {
        cell.style.removeProperty("display");
      } else {
        // eslint-disable-next-line no-param-reassign
        cell.style.display = "none";
      }
    });
  }

  toggleColumn(name, visible) {
    const th = this.tableTarget.querySelector(`th#${name}`);
    const index = [...th.parentNode.children].indexOf(th) + 1;
    const cells = this.tableTarget.querySelectorAll(
      `th:nth-child(${index}),td:nth-child(${index})`,
    );
    cells.forEach((cell) => {
      if (visible) {
        cell.style.removeProperty("display");
      } else {
        // eslint-disable-next-line no-param-reassign
        cell.style.display = "none";
      }
    });
  }

  handleChange(e) {
    const {
      currentTarget: { checked, name },
    } = e;
    this.toggleColumn(name, checked);

    // Writes the latest value of `current` to localStorage.
    this.current[name] = checked;
    localStorage.setItem(this.storageKey, JSON.stringify(this.current));
  }
}
