import { Controller } from "@hotwired/stimulus";

import { IS_WEBAPP } from "@bryq/src/settings";

export default class extends Controller {
  static targets = ["loaderOverlay"];

  static classes = ["closed"];

  /**
   * Dispatches the `show-loader` event that can be listened to by other
   * controllers or captured by the `LoaderOverlayTurbo` React component.
   *
   * Outside of react, will show the loader overlay.
   */
  showLoader() {
    if (IS_WEBAPP) {
      this.dispatch("show-loader");
    } else {
      this.loaderOverlayTarget.classList.remove(this.closedClass);
    }
  }

  /**
   * Dispatches the `hide-loader` event that can be listened to by other
   * controllers or captured by the `LoaderOverlayTurbo` React component.
   *
   * Outside of react, will hide the loader overlay.
   */
  hideLoader() {
    if (IS_WEBAPP) {
      this.dispatch("hide-loader");
    } else {
      this.loaderOverlayTarget.classList.add(this.closedClass);
    }
  }
}
