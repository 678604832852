import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handleFormSubmitEnd(event) {
    const { detail: { success } = {} } = event;

    if (success) {
      this.dispatch("form-submitted-with-success");
    }
  }
}
