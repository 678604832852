import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "checkButton", "uncheckButton"];

  static classes = ["hidden"];

  connect() {
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.classList.add(this.hiddenClass);
    }
    this.toggleButtons();
  }

  toggle() {
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.click();
      this.toggleButtons();
    }
  }

  /**
   * Adjusts the buttons visibility based on the checkbox state.
   */
  toggleButtons() {
    if (this.hasCheckboxTarget) {
      if (this.checkboxTarget.checked) {
        if (this.hasCheckButtonTarget) {
          this.checkButtonTarget.classList.add(this.hiddenClass);
        }
        if (this.hasUncheckButtonTarget) {
          this.uncheckButtonTarget.classList.remove(this.hiddenClass);
        }
      } else {
        if (this.hasCheckButtonTarget) {
          this.checkButtonTarget.classList.remove(this.hiddenClass);
        }
        if (this.hasUncheckButtonTarget) {
          this.uncheckButtonTarget.classList.add(this.hiddenClass);
        }
      }
    }
  }
}
