import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "contentWrapper",
    "overlay",
    "toggleButton",
    "item",
    "groupItem",
  ];

  static classes = ["open", "bodyMenuOpen", "itemActive"];

  toggle() {
    this.element.classList.toggle(this.bodyMenuOpenClass);
    this.toggleButtonTarget.classList.toggle(this.openClass);
    this.contentWrapperTarget.classList.toggle(this.openClass);
    this.overlayTarget.classList.toggle(this.openClass);
  }

  /**
   * Selects an item in the menu and marks it as active.
   * There are two types of items:
   * - Group items: They are the main items in the menu and they serve as proxy
   *   for another item in the menu. They have a url and should specify the
   *   `data-menu-proxy-for-item-id-param` attribute with the id of the item
   *   that they are a proxy for.
   * - Items: They are the actual items in the menu. They may beloon to a group
   *   item. If they should specify the `data-menu-belongs-to-group-id-param`
   *   attribute with the id of the group item that they belong to.
   *   When clicking on one or the other, the item will be marked as active
   *   and the related group/item will also be marked as active if it exists.
   */
  selectItem(event) {
    const {
      currentTarget,
      params: { proxyForItemId, belongsToGroupId },
    } = event;

    this.itemTargets.forEach((item) => {
      item.classList.remove(this.itemActiveClass);
    });
    currentTarget.classList.add(this.itemActiveClass);

    if (!proxyForItemId && !belongsToGroupId) {
      return;
    }

    if (belongsToGroupId) {
      const itemTarget = this.itemTargets.find(
        (item) => item.id === belongsToGroupId,
      );
      if (!itemTarget) {
        return;
      }
      itemTarget.classList.add(this.itemActiveClass);
    }

    if (proxyForItemId) {
      const itemTarget = this.itemTargets.find(
        (item) => item.id === proxyForItemId,
      );
      if (!itemTarget) {
        return;
      }
      itemTarget.classList.add(this.itemActiveClass);
    }
  }
}
