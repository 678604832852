import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["search", "submitButton"];

  static classes = ["hidden"];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  // Submits the form when search is cleared using the `x` button.
  handleSearch(e) {
    if (e.currentTarget.value === "") {
      this.submit();
    }
  }
}
