import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitPictureButton"];

  /**
   * This method filters the submit events from the form validation and
   * dispatches the `main-submit-start` event that then can be listened to by
   * other controllers interested in the fomr submission such as the
   * `LoaderOverlay` controller.
   * @param {Event} event
   */
  submitStart(event) {
    const submitterName = event.detail.formSubmission.submitter.name;
    if (submitterName === "_validate_form") {
      return;
    }
    this.dispatch("main-submit-start", event);
  }

  /**
   * This method filters the submit events from the form validation and
   * dispatches the main-submit-end event that then can be listened to by other
   * controllers interested in the fomr submission such as the `LoaderOverlay`
   * controller.
   * @param {Event} event
   */
  submitEnd(event) {
    const submitterName = event.detail.formSubmission.submitter.name;
    if (submitterName === "_validate_form") {
      return;
    }
    this.dispatch("main-submit-end", event);
  }

  /**
   * This method is used to submit the picture from the preview.
   * @param {Event} event
   */
  submitPicture(event) {
    const submitPictureButtonTargetValue =
      this.submitPictureButtonTarget.getAttribute("value");
    if (
      event.type ===
      "utils--image-drop-preview:file-input-and-image-preview-set"
    ) {
      this.submitPictureButtonTarget.setAttribute(
        "value",
        "_submission_from_preview",
      );
    }
    this.submitPictureButtonTarget.click();
    this.submitPictureButtonTarget.setAttribute(
      "value",
      submitPictureButtonTargetValue,
    );
  }
}
