import { Controller } from "@hotwired/stimulus";
import { inView } from "motion";

import { IS_WEBAPP } from "@bryq/src/settings";

/**
 * This controller is used to navigate to sections of the page.
 * It must be attached on the highest element of the page so it can
 * automatically add the distance from the top of the page to the parent when
 * navigating to a section. This way, we ensure that the scroll is always
 * correct and the section is not hidden by the header at the end of the scroll.
 */
export default class extends Controller {
  static targets = ["section", "link", "baseHeader", "stickyHeader"];

  static classes = ["active", "hidden", "stickyHeaderVisible"];

  /**
   * Initializes the controller.
   * Creates attributes to store the `inView` observers stop functions.
   * If the page has a hash, scrolls to the section with the id in the hash.
   */
  initialize() {
    this.sectionInViewObserversStopFunctions = {};
    this.baseHeaderInViewObserverStopFunction = null;

    const timeoutDuration = IS_WEBAPP ? 500 : 100;
    if (window.location.hash) {
      setTimeout(() => {
        this.scrollToSection(window.location.hash);
      }, timeoutDuration);
    } else {
      // Ensures the page is scrolled to the top when it is loaded and there
      // is no hash in the URL.
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }

  /**
   * When a section target is connected, it adds an `inView` observer to it to
   * detect when it becomes the main section visible on the page.
   */
  sectionTargetConnected(section) {
    this.sectionInViewObserversStopFunctions[section.id] =
      this.observeSectionInViewEvents(section);
  }

  /**
   * When a section target is disconnected, it removes the `inView` observer
   * from it to prevernt memory leaks.
   */
  sectionTargetDisconnected(section) {
    this.sectionInViewObserversStopFunctions[section.id]();
    delete this.sectionInViewObserversStopFunctions[section.id];
  }

  /**
   * When the base header target is connected, it adds an `inView` observer to
   * it to detect when it enter/leaves the page to control the visibility of
   * the sticky header.
   */
  baseHeaderTargetConnected(target) {
    // Check if the base header is actually in the view. If it not show, the
    // sticky header.
    setTimeout(() => {
      const rect = target.getBoundingClientRect();

      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);
      if (!isVisible) {
        this.stickyHeaderTarget.classList.add(this.stickyHeaderVisibleClass);
      }
    }, 100);

    this.baseHeaderInViewObserverStopFunction =
      this.setStickyHeaderVisibility();
  }

  /**
   * When the base header target is disconnected, it removes the `inView`
   * observer from it to prevent memory leaks.
   */
  baseHeaderTargetDisconnected() {
    if (this.baseHeaderInViewObserverStopFunction !== null) {
      this.baseHeaderInViewObserverStopFunction();
    }
    this.baseHeaderInViewObserverStopFunction = null;
  }

  /**
   * Creates an `inView` observer for a section target. When the section is in
   * view, it sets the active link for this section. A new observer is created
   * when the view to make sure we can detect when the section is in view
   * again.
   * Note: the callback passed to the `inView` function is only called once,
   * hence the recursion.
   */
  observeSectionInViewEvents(target) {
    return inView(
      target,
      (info) => {
        this.setActiveLink(info.target);
        return (leavingInfo) => {
          this.observeSectionInViewEvents(leavingInfo.target);
        };
      },
      { margin: "-25% 0px -25% 0px" },
    );
  }

  /**
   * Sets the visibility of the sticky header depending on the base header
   * being in view or not.
   * When the base header leaves the view, the observer is created again to
   * detect when the base header gets view again.
   * Note: the callback passed to the `inView` function is only called once,
   * hence the recursion.
   */
  setStickyHeaderVisibility() {
    return inView(this.baseHeaderTarget, () => {
      this.stickyHeaderTarget.classList.remove(this.stickyHeaderVisibleClass);

      return () => {
        this.stickyHeaderTarget.classList.add(this.stickyHeaderVisibleClass);
        this.setStickyHeaderVisibility();
      };
    });
  }

  /**
   * Sets the active link for a section.
   */
  setActiveLink(section) {
    this.linkTargets.forEach((link) => {
      if (link.getAttribute("href") === `#${section.id}`) {
        link.classList.add(this.activeClass);
      } else {
        link.classList.remove(this.activeClass);
      }
    });
  }

  /**
   * Navigates to a section when a link is clicked and updates the history.
   */
  navigateToSection(event) {
    event.preventDefault();
    const { currentTarget } = event;
    const sectionId = currentTarget.getAttribute("href");
    if (sectionId === "#" || !sectionId.startsWith("#")) {
      return;
    }
    this.scrollToSection(sectionId);
    window.history.replaceState({}, "", sectionId);
  }

  /**
   * Scrolls to a section based on its hashed id.
   */
  scrollToSection(sectionIdWithHash) {
    const section = document.querySelector(sectionIdWithHash);
    if (!section) {
      return;
    }
    const stickyHeaderHeight = this.hasStickyHeaderTarget
      ? this.stickyHeaderTarget.offsetHeight + 16
      : 0;
    const distanceFromCurrentPosition = section.offsetTop - stickyHeaderHeight;
    window.scrollTo({ top: distanceFromCurrentPosition, behavior: "smooth" });
  }
}
