/* eslint import/prefer-default-export: "off" */
import { mergeAttributes } from "@tiptap/core";
import Paragraph from "@tiptap/extension-paragraph";

/**
 * Extends Paragraph to use div tags instead of p tags.
 */
export const Div = Paragraph.extend({
  parseHTML() {
    return [{ tag: "div" }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
