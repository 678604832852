/* eslint import/prefer-default-export: "off" */
import { breakpoints } from "../utils/responsive";
import { getMethodFromController } from "../utils/stimulus";

/**
 * Allows a controller to know when a breakpoint value is changing.
 * - The controller should define the `breakpointChange` method.
 */
export const useResponsive = (controller) => {
  const breakpointChangeEventListener = (event) => {
    const method = getMethodFromController(controller, "breakpointChange");
    method.call(controller, event.detail);
  };

  const observe = () => {
    window.addEventListener(
      "breakpointchange",
      breakpointChangeEventListener,
      false,
    );
  };

  const unobserve = () => {
    window.removeEventListener(
      "breakpointchange",
      breakpointChangeEventListener,
      false,
    );
  };

  // keep a copy of the current disconnect() function of the controller
  // to support composing several behaviors
  const controllerDisconnect = controller.disconnect.bind(controller);

  Object.assign(controller, {
    disconnect() {
      unobserve();
      controllerDisconnect();
    },
  });

  // Call the controller's `breakpointChange` method with the current breakpoint
  // when the behavior is attached to the controller.
  controller.breakpointChange(breakpoints);
  observe();

  return [observe, unobserve];
};
