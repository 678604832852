import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "copyButton"];

  static classes = ["supported"];

  static values = {
    successMessage: String,
  };

  connect() {
    if ("clipboard" in navigator) {
      this.copyButtonTarget.classList.add(this.supportedClass);
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.sourceTarget.innerText);
    this.dispatch("copied-to-clipboard", {
      detail: {
        content: this.successMessageValue,
        status: "success",
        copiedContent: this.sourceTarget.innerText,
      },
    });
  }
}
