import { Controller } from "@hotwired/stimulus";

import { IS_CYRPRESS } from "@bryq/src/settings";
import { getCustomPropertyValue } from "@bryq/src/utils/css";
import Highcharts from "@bryq/src/utils/highcharts";
import { readJsonScriptValue } from "@bryq/src/utils/json_script";

/**
 * Represent sten score related to diffenent indicator groups for a candidate
 * and a pool of candition on a spider chart.
 * Animation are deactivated for Cypress snapshots
 */
export default class extends Controller {
  static targets = ["chartContainer"];

  static classes = ["xAxisLabel"];

  static values = {
    id: String,
  };

  connect() {
    const data = readJsonScriptValue(this.idValue);
    this.renderChart(data);
  }

  renderChart(data) {
    const colorAccentPink50 = getCustomPropertyValue("--color-accent-pink-50");
    const colorAccentPink30 = getCustomPropertyValue("--color-accent-pink-30");
    const colorAccentBlue60 = getCustomPropertyValue("--color-accent-blue-60");
    const colorAccentBlue70 = getCustomPropertyValue("--color-accent-blue-70");
    const { xAxisLabelClass } = this;

    const series = [
      {
        pointPlacement: "on",
        type: "area",
        zIndex: 1,
        color: colorAccentBlue70,
        lineColor: colorAccentBlue70,
        fillOpacity: 0.5,
        marker: { symbol: "circle" },
        ...data.series[0],
      },
    ];
    if (data.series.length === 2) {
      series.push({
        pointPlacement: "on",
        type: "area",
        zIndex: 0,
        color: colorAccentPink30,
        lineColor: colorAccentPink30,
        fillOpacity: 0.4,
        marker: { symbol: "circle" },
        ...data.series[1],
      });
    }

    const chartOptions = {
      chart: {
        polar: true,
        type: "line",
        animation: IS_CYRPRESS === false,
      },
      plotOptions: {
        series: {
          animation: IS_CYRPRESS === false,
        },
      },
      title: {
        text: null,
      },
      colors: [colorAccentBlue60, colorAccentPink50],
      legend: {
        align: "left",
        verticalAlign: "top",
        layout: "horizontal",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: data.x_axis_scale_domain,
        tickmarkPlacement: "on",
        lineWidth: 0,
        labels: {
          useHTML: true,
          formatter() {
            return `<span class="body-medium-bold color-neutral-90 ${xAxisLabelClass}">${this.value}</span>`;
          },
        },
      },
      yAxis: {
        gridLineInterpolation: "polygon",
        lineWidth: 0,
        min: data.y_axis_scale_domain[0],
        max: data.y_axis_scale_domain[1],
        tickAmount: 6,
        gridLineWidth: 2,
        labels: { enabled: false },
      },
      tooltip: {
        outside: true,
        shared: true,
        pointFormat:
          '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>',
      },
      series,
    };
    Highcharts.chart(this.chartContainerTarget, chartOptions);
  }
}
