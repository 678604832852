import { Controller } from "@hotwired/stimulus";

import { useEmailSubjectEditor } from "@bryq/src/behaviors/use_email_subject_editor";
import { useEmailTextEditor } from "@bryq/src/behaviors/use_email_text_editor";

export default class extends Controller {
  static targets = ["body", "subject"];

  static values = { emailVariables: Array };

  connect() {
    useEmailSubjectEditor(this, this.subjectTarget, {
      variables: this.emailVariablesValue,
    });

    useEmailTextEditor(this, this.bodyTarget, {
      variables: this.emailVariablesValue,
    });
  }

  /**
   * This method filters the submit events from the form validation and
   * dispatches the `main-submit-start` event that then can be listened to by
   * other controllers interested in the fomr submission such as the
   * `LoaderOverlay` controller.
   * @param {Event} event
   */
  submitStart(event) {
    const submitterName = event.detail.formSubmission.submitter.name;
    if (submitterName === "_validate_form") {
      return;
    }
    this.dispatch("main-submit-start", event);
  }

  /**
   * This method filters the submit events from the form validation and
   * dispatches the main-submit-end event that then can be listened to by other
   * controllers interested in the fomr submission such as the `LoaderOverlay`
   * controller.
   * @param {Event} event
   */
  submitEnd(event) {
    const submitterName = event.detail.formSubmission.submitter.name;
    if (submitterName === "_validate_form") {
      return;
    }
    this.dispatch("main-submit-end", event);
    const { detail: { success } = {} } = event;
    if (success) {
      this.dispatch("form-submitted-with-success");
    }
  }
}
