import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = ["select", "submitButton"];

  static classes = ["hidden", "select"];

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add(this.hiddenClass);
    }

    if (this.hasSelectTarget) {
      this.selectTarget.classList.add(...this.selectClasses);

      // Assigns a HTML to each option based on its value.
      // This is the only way to keep the icons showing in the selection and
      // not just the dropdown list.
      // Also assigns a class to each option based on its value.
      // This is a global class that is referenced in the CSS module.
      // There is no way to set an arbitrary data attribute on the select
      // options from here, which is carried forward by SlimSelect.
      // The alternative would be to set 5 classes on the controller for each
      // status, which seemed excessive.
      this.selectTarget.querySelectorAll("option").forEach((option) => {
        if (option.value) {
          // eslint-disable-next-line no-param-reassign
          option.dataset.html = `
            <div class="status-select-option status-select-option-${option.value}">
              <span>${option.innerHTML}</span>
            </div>
          `;
        }
      });

      this.statusSelect = new SlimSelect({
        select: this.selectTarget,
        settings: {
          showSearch: false,
        },
      });
    }
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    }
  }

  disconnect() {
    if (this.statusSelect) {
      this.statusSelect.destroy();
    }
  }
}
