import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "preview"];

  static classes = ["active"];

  previewUrl = null;

  disconnect() {
    this.revokePreview();
  }

  // eslint-disable-next-line class-methods-use-this
  noop(event) {
    event.preventDefault();
  }

  setActive() {
    this.element.classList.add(this.activeClass);
  }

  setInactive() {
    this.element.classList.remove(this.activeClass);
  }

  setFileInputAndImagePreview(event) {
    event.preventDefault();
    this.element.classList.remove(this.activeClass);

    // The server will deal will only accept 1 file.
    const files = event.target.files || event.dataTransfer.files;
    this.inputTarget.files = files;

    // Takes the first file of the list and create a preview. Alwways make sure
    // that any previous preview has been revoked.
    if (files.length === 0) {
      return;
    }
    const file = files.item(0);
    this.revokePreview();
    this.previewUrl = URL.createObjectURL(file);
    this.previewTarget.style.backgroundImage = `url(${this.previewUrl})`;

    // Dispatches an event that anohter controller can listen to to eventually
    // submit the form.
    this.dispatch("file-input-and-image-preview-set");
  }

  revokePreview() {
    if (this.previewUrl !== null) {
      URL.revokeObjectURL(this.previewUrl);
    }
  }
}
