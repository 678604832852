/* eslint import/prefer-default-export: "off" */
import { Extension, isNodeSelection, posToDOMRect } from "@tiptap/core";
import tippy from "tippy.js";

/**
 * Returns the rect of the current editor selection, to render the tippy
 * tooltip in the right place.
 */
function getReferenceClientRect(editor) {
  const { view } = editor;
  const { state } = view;
  const { selection } = state;
  const { ranges } = selection;
  const $from = Math.min(...ranges.map((range) => range.$from.pos));
  const $to = Math.max(...ranges.map((range) => range.$to.pos));

  if (isNodeSelection(state.selection)) {
    const node = view.nodeDOM($from);
    if (node) {
      return node.getBoundingClientRect();
    }
  }

  return posToDOMRect(view, $from, $to);
}

/**
 * Renders a link dialog form inside a tippy tooltip, whenever the "toggleLink"
 * command is called.
 *
 * The tippy instance is destroyed and recreated for every call.
 */
export const LinkDialog = Extension.create({
  name: "linkDialog",

  addCommands() {
    return {
      toggleLink: () => () => {
        let tooltip = null;

        const { editor } = this;
        const {
          options: { element: editorElement },
        } = editor;

        const current = editor.getAttributes("link").href || "";

        // Creates the link form.
        const form = document.createElement("form");
        form.classList.add("b-form", "b-editor-link-dialog-form");

        const handleSubmit = (e) => {
          e.preventDefault();
          const data = new FormData(e.target);
          editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: data.get("url") })
            .run();
          tooltip?.hide();
        };

        form.addEventListener("submit", handleSubmit);

        // Creates the input field.
        const input = document.createElement("input");
        input.setAttribute("autofocus", "");
        input.setAttribute("name", "url");
        input.setAttribute("type", "url");
        // eslint-disable-next-line no-param-reassign
        input.value = current;
        form.appendChild(input);

        // Creates the unlink button.
        const button = document.createElement("button");
        button.setAttribute("type", "button");
        button.setAttribute("title", "Remove Link");
        const handleUnlink = (e) => {
          e.preventDefault();
          editor.chain().focus().extendMarkRange("link").unsetLink().run();
          tooltip?.hide();
        };

        button.addEventListener("click", handleUnlink);

        if (current) {
          form.appendChild(button);
        }

        // Creates the tooltip.
        tooltip = tippy(editorElement, {
          animation: "fade",
          allowHTML: true,
          content: form,
          delay: [120, 120],
          getReferenceClientRect: () => getReferenceClientRect(editor),
          interactive: true,
          onHidden: (instance) => {
            button.removeEventListener("click", handleUnlink);
            form.removeEventListener("submit", handleSubmit);
            instance.destroy();
          },
          onShown: () => {
            input.focus();
          },
          showOnCreate: true,
          trigger: "manual",
        });
      },
    };
  },
});
